import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-menuredirect',
  templateUrl: './menuredirect.component.html',
  styleUrls: ['./menuredirect.component.css']
})
export class MenuredirectComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
    this.router.navigateByUrl('/menu/restaurante/');
  }

}

import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  constructor(private  http: HttpClient) { }

  getOrderProducts() {
    const headers = new HttpHeaders({'content-Type': 'application/json'});
    return this.http.get(environment.BASE_API + '/orderProducts', {headers});
  }

  removeProduct(formData) {
    return this.http.post(environment.BASE_API + '/removeProduct', formData);
  }

  editAmountProduct(formData) {
    return this.http.post(environment.BASE_API + '/orderProduct', formData);
  }

  getModifiers(product: number) {
    const headers = new HttpHeaders({'content-Type': 'application/json'});
    return this.http.get(environment.BASE_API + '/modificadores/' + product, {headers});
  }

  getProductModifiers(idOrderProduct: number) {
    const headers = new HttpHeaders({'content-Type': 'application/json'});
    return this.http.get(environment.BASE_API + '/productoModificador/' + idOrderProduct, {headers});
  }

  postProductModifiers(formData, idOrderProduct: number) {
    return this.http.post(environment.BASE_API + '/productoModificador/' + idOrderProduct, formData);
  }

  sendOrder(formData) {
    return this.http.post(environment.BASE_API + '/sendOrder', formData);
  }

}

import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import {SucursalService} from '../../services/sucursal/sucursal.service';
import {ContactService} from '../../services/contact/contact.service';
import {NgxIzitoastService} from 'ngx-izitoast';
@Component({
  selector: 'app-proveedor',
  templateUrl: './proveedor.component.html',
  styleUrls: ['./proveedor.component.css']
})
export class ProveedorComponent implements OnInit {

  resp: any;
  listSucursales: any[] = [];
  spinnerSendingVisible: boolean;
  contacto: any = {
    nombre: '',
    telefono: '',
    email: '',

    mensaje: ''
  };
  uploadFile: File = null;
  constructor(private sucursalService: SucursalService,
              private contactService: ContactService,
              public iziToast: NgxIzitoastService) { }

  ngOnInit() {
    this.getSucursales();
    this.spinnerSendingVisible = false;
  }
  getSucursales() {
    // llamar el servicio periodos y guardarlo
    this.sucursalService.getSucursales().subscribe(
      res => {
        this.resp = res;
        this.listSucursales = this.resp.content;

        console.log('sucursales' + JSON.stringify( this.listSucursales));
      },
      err => {
        console.error(err);
        this.iziToast.show(
          {
            title: 'Lo sentimos, algo salio mal al cargar las sucursales.<br> revisa tu conexión e intenta de nuevo.',
            titleColor: '#f8f8ff',
            color: '#ff5131',
            position: 'topRight'
          });
      }
    ); // fin del subscribe

  }

  sendMessage() {
    if (this.validations()) {
      console.log(this.contacto);
      this.postMessage();
    }

  }

  postMessage() {
    const formData = new FormData();
    this.spinnerSendingVisible = true; // mostrar spinner de cargando

    formData.append('nombre', this.contacto.nombre );
    formData.append('email', this.contacto.email );
    formData.append('ticket', this.uploadFile );
    formData.append('mensaje', this.contacto.mensaje );



    this.contactService.sendContactoProv(formData).subscribe(
      res => {
        this.resp = res;
        // this.spinnerSendingVisible = false; // <- ocultar spinner
        if (this.resp.success) {
          console.log('response:' + JSON.stringify(this.resp));

          this.cleanModels();
          this.spinnerSendingVisible = false; // <- ocultar spinner
          this.iziToast.show(
            {
              title: 'Muchas Gracias se han enviado tus comentarios,<br> esto nos ayuda a mejorar constantemente..',
              titleColor: '#f8f8ff',
              color: '#4CAF50',
              position: 'topRight',
              closeOnEscape: true,

            });

        } else {
          this.spinnerSendingVisible = false; // <- ocultar spinner
          this.iziToast.show({
            image: './assets/ico/error.png',
            title: this.resp.msg_error,
            position: 'topCenter',
            theme: 'dark',

          });
        }
      },
      err => {
        this.spinnerSendingVisible = false; // <- ocultar spinner
        console.error(err);
        this.iziToast.show(
          {
            title: 'Lo sentimos, algo salio mal al enviar tu orden.<br>revisa tu conexión e intenta de nuevo.',
            titleColor: '#f8f8ff',
            color: '#ff5131',
            position: 'topRight'
          });
      }
    );


  }



  onPDFChange(files: FileList) {
    this.uploadFile = files.item(0);
    // console.log('File Change',e);
  }


  validations() {
    let valid = true;
    const empty = '';
    // valid name
    if (this.contacto.nombre.toString() == empty) {

      this.iziToast.show({
        image: './assets/ico/error.png',
        title: 'El nombre no puede estar vacío',
        position: 'topCenter',
        theme: 'dark',

      });
      valid = false;
      $('#name').focus();
      return ;
    }
    // valid email
    if (!this.validateEmail(this.contacto.email)) {// si el email no es valido

      this.iziToast.show({
        image: './assets/ico/error.png',
        title: 'Por favor ingresa una dirección de correo valida',
        position: 'topCenter',
        theme: 'dark',

      });
      $('#email').focus();
      valid = false;
      return ;
    }



    return valid;
  }


  validateEmail(email: string) {
    // tslint:disable-next-line:max-line-length
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }




  validCel() {

    if (this.contacto.telefono.toString().length > 10) {

      this.iziToast.show({
        image: './assets/ico/error.png',
        title: 'El numero de teléfono celular no puede ser tan largo',
        position: 'topCenter',
        theme: 'dark',
      });
      // this.order.cel = this.order.cel.substring(0, 12);
      console.log(this.contacto.telefono.toString().substring(0, 10));

      this.contacto.telefono = this.contacto.telefono.toString().substring(0, 10);
      $('#cel').focus();

    }

  }
  cleanModels() {

    this.contacto.nombre = '';
    this.contacto.telefono = '';
    this.contacto.email = '';
    this.contacto.sucursal = '-99';
    this.contacto.mensaje = '';


  }
}

import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import {SucursalService} from '../../services/sucursal/sucursal.service';
import {NgxIzitoastService} from 'ngx-izitoast';
import {ContactService} from '../../services/contact/contact.service';
@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})
export class ContactoComponent implements OnInit {

  resp: any;
  listSucursales: any[] = [];
  spinnerSendingVisible: boolean;
  contacto: any = {
    nombre: '',
    telefono: '',
    email: '',
    sucursal: '',
    mensaje: ''
  };
  constructor(private sucursalService: SucursalService,
              private contactService: ContactService,
              public iziToast: NgxIzitoastService) { }

  ngOnInit() {
    this.getSucursales();
    this.contacto.sucursal = '-99';
    this.spinnerSendingVisible = false;
  }
  getSucursales() {
    // llamar el servicio periodos y guardarlo
    this.sucursalService.getSucursales().subscribe(
      res => {
        this.resp = res;
        this.listSucursales = this.resp.content;

        console.log('sucursales' + JSON.stringify( this.listSucursales));
      },
      err => {
        console.error(err);
        this.iziToast.show(
          {
            title: 'Lo sentimos, algo salio mal al cargar las sucursales.<br> revisa tu conexión e intenta de nuevo.',
            titleColor: '#f8f8ff',
            color: '#ff5131',
            position: 'topRight'
          });
      }
    ); // fin del subscribe

  }

  sendMessage() {
    if (this.validations()) {
      console.log(this.contacto);
      this.postMessage();
    }

  }
  onImageChange(files: FileList) {
    this.contacto.ticket = files.item(0);
    console.log('File size', this.contacto.ticket.size);
    console.log('File type', this.contacto.ticket.type);
    // || this.invoice.ticket.type.toString() !== 'image/jpeg'
    if (this.contacto.ticket.type.toString() == 'image/png' ||
        this.contacto.ticket.type.toString() == 'image/jpeg' ) {


        if (this.contacto.ticket.size > 10485760) {
          this.iziToast.show({
            image: './assets/ico/error.png',
            title: 'El tamaño del archivo no puede ser mayor a 10 MB.',
            position: 'topCenter',
            theme: 'dark',

          });
          this.contacto.ticket = null;
          $('#image-ticket').focus();
          return;
        }

        console.log('File Change', this.contacto.ticket);

    } else {
      this.iziToast.show({
        image: './assets/ico/error.png',
        title: 'Solo puede cargar imagenes JPG, JPEG, Y PNG.',
        position: 'topCenter',
        theme: 'dark',

      });
      this.contacto.ticket = null;
      $('#image-ticket').focus();
      return;
    }


  }
  postMessage() {
    const formData = new FormData();
    this.spinnerSendingVisible = true; // mostrar spinner de cargando

    formData.append('nombre', this.contacto.nombre );
    formData.append('telefono', this.contacto.telefono );
    formData.append('email', this.contacto.email );
    formData.append('sucursal', this.contacto.sucursal );
    formData.append('mensaje', this.contacto.mensaje );
    formData.append('ticket', this.contacto.ticket );



    this.contactService.sendContactoInfo(formData).subscribe(
      res => {
        this.resp = res;
        // this.spinnerSendingVisible = false; // <- ocultar spinner
        if (this.resp.success) {
          console.log('response:' + JSON.stringify(this.resp));

          this.cleanModels();
          this.spinnerSendingVisible = false; // <- ocultar spinner
          this.iziToast.show(
            {
              title: 'Muchas Gracias se han enviado tus comentarios,<br> esto nos ayuda a mejorar constantemente.',
              titleColor: '#f8f8ff',
              color: '#4CAF50',
              position: 'topRight',
              closeOnEscape: true,

            });

        } else {
          this.spinnerSendingVisible = false; // <- ocultar spinner
          this.iziToast.show({
            image: './assets/ico/error.png',
            title: this.resp.msg_error,
            titleColor: '#f8f8ff',
            color: '#ff5131',
            position: 'topRight',
            theme: 'dark',

          });
        }
      },
      err => {
        this.spinnerSendingVisible = false; // <- ocultar spinner
        console.error(err);
        this.iziToast.show(
          {
            title: 'Lo sentimos, algo salio mal al enviar sus comentarios.<br>revisa tu conexión e intenta de nuevo.',
            titleColor: '#f8f8ff',
            color: '#ff5131',
            position: 'topRight'
          });
      }
    );


  }

  validations() {
    let valid = true;
    const empty = '';
    // valid name
    if (this.contacto.nombre.toString() == empty) {

      this.iziToast.show({
        image: './assets/ico/error.png',
        title: 'El nombre no puede estar vacío',
        position: 'topCenter',
        theme: 'dark',

      });
      valid = false;
      $('#name').focus();
      return ;
    }
    // valid email
    if (!this.validateEmail(this.contacto.email)) {// si el email no es valido

      this.iziToast.show({
        image: './assets/ico/error.png',
        title: 'Por favor ingresa una dirección de correo valida',
        position: 'topCenter',
        theme: 'dark',

      });
      $('#email').focus();
      valid = false;
      return ;
    }
    // valid cel
    if (this.contacto.telefono.toString() == empty) {

      this.iziToast.show({
        image: './assets/ico/error.png',
        title: 'El número de teléfono celular no puede estar vacío',
        position: 'topCenter',
        theme: 'dark',

      });
      valid = false;
      $('#cel').focus();
      return ;
    }
    // valid sucursal
    if (this.contacto.sucursal.toString() == '-99') {

      this.iziToast.show({
        image: './assets/ico/error.png',
        title: 'Selecciona una sucursal por favor',
        position: 'topCenter',
        theme: 'dark',

      });
      valid = false;
      $('#sucursal').focus();
      return ;
    }


    return valid;
  }


  validateEmail(email: string) {
    // tslint:disable-next-line:max-line-length
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }




  validCel() {

    if (this.contacto.telefono.toString().length > 10) {

      this.iziToast.show({
        image: './assets/ico/error.png',
        title: 'El numero de teléfono celular no puede ser tan largo',
        position: 'topCenter',
        theme: 'dark',
      });
      // this.order.cel = this.order.cel.substring(0, 12);
      console.log(this.contacto.telefono.toString().substring(0, 10));

      this.contacto.telefono = this.contacto.telefono.toString().substring(0, 10);
      $('#cel').focus();

    }

  }
  cleanModels() {

    this.contacto.nombre = '';
    this.contacto.telefono = '';
    this.contacto.email = '';
    this.contacto.sucursal = '-99';
    this.contacto.mensaje = '';


  }
}

import {Action} from '@ngrx/store';

export const REFRESH = '[refresh] Refrescar';
export const NOTREFRESH = '[refresh] NoRefrescar';

export const DELIVER = '[deliver] Enviar';
export const COLLECT = '[deliver] RecogerRestaurante';

export const GETMODIFIERS = '[refresh] ObtenerModificadores';
export const NOTGETMODIFIERS = '[refresh] NoObtenerModificadores';
export const REFRESHMODIFIERS = '[refresh] RefrescarModificadores';
export const NOTREFRESHMODIFIERS = '[refresh] NoRefrescarModificadores';


export const REFRESHSUC = '[refresh] RefrescarSucursal';
export const NOTREFRESHSUC = '[refresh] NoRefrescarSucursal';

export const REFRESHSUC2 = '[refresh2] RefrescarSucursal2';
export const NOTREFRESHSUC2 = '[refresh2] NoRefrescarSucursal2';

export const REFRESHSUC3 = '[refresh3] RefrescarSucursal3';
export const NOTREFRESHSUC3 = '[refresh3] NoRefrescarSucursal3';

export const REFRESHSUC4 = '[refresh4] RefrescarSucursal4';
export const NOTREFRESHSUC4 = '[refresh4] NoRefrescarSucursal4';
// refresh order
export class RefreshAction implements Action {
    readonly type = REFRESH;
}

export class NotRefreshAction implements Action {
  readonly type = NOTREFRESH;
}

// cambiar la opción de envio
export class DeliverAction implements Action {
  readonly type = DELIVER;
}

export class CollectAction implements Action {
  readonly type = COLLECT;
}


// refresh modificadores
export class GetModifiers implements Action {
  readonly type = GETMODIFIERS;
}
export class NotGetModifiers implements Action {
  readonly type = NOTGETMODIFIERS;
}

export class RefreshModifiers implements Action {
  readonly type = REFRESHMODIFIERS;
}

export class NotRefreshModifiers implements Action {
  readonly type = NOTREFRESHMODIFIERS;
}

// refresh sucursal
export class RefreshSucAction implements Action {
  readonly type = REFRESHSUC;
}

export class NotRefreshSucAction implements Action {
  readonly type = NOTREFRESHSUC;
}
// refresh sucursal 2
export class RefreshSucAction2 implements Action {
  readonly type = REFRESHSUC2;
}

export class NotRefreshSucAction2 implements Action {
  readonly type = NOTREFRESHSUC2;
}

// refresh sucursal 3
export class RefreshSucAction3 implements Action {
  readonly type = REFRESHSUC3;
}

export class NotRefreshSucAction3 implements Action {
  readonly type = NOTREFRESHSUC3;
}

// refresh sucursal 4
export class RefreshSucAction4 implements Action {
  readonly type = REFRESHSUC4;
}

export class NotRefreshSucAction4 implements Action {
  readonly type = NOTREFRESHSUC4;
}

import {AfterViewChecked, Component, OnInit} from '@angular/core';
import * as $ from 'jquery';
import {HttpClient} from '@angular/common/http';
import {CoberturaCiudadesModel} from '../../models/coberturaCiudadesModel';
import {Router} from '@angular/router';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  public listCiudades: CoberturaCiudadesModel[] = [];
  public indiceCity: number;
  private resp: any;

  constructor(private httpClient: HttpClient,
              private router: Router) {
  }

  ngOnInit() {


    // aqui va el javascript

    // localStorage.setItem('modalView', 'true');
    // console.log(localStorage.getItem('modalViewNavidad'));

    // if (localStorage.getItem('modalViewNavidad') == null) {
    //document.getElementById('modalM').click();
    
   this.httpClient.get('/assets/json/coberturaSucursales.json').subscribe(data => {
      // console.log(data);
      this.resp = data;
      this.listCiudades = this.resp;
      console.log(this.listCiudades);

      this.indiceCity = 0;

    });

  }

  clickCity(): void {
    this.closeModal();
    this.router.navigateByUrl('/un-momento-unico-con-mama');
  }
  closeModal() {
    document.getElementById('btn-cerrar').click();
   /*console.log(localStorage.getItem('modalViewNavidad'));
    if (localStorage.getItem('modalViewNavidad') == null) {
      localStorage.setItem('modalViewNavidad', 'false');
    }*/
  }


  openOtherModal(){

    setTimeout(function(){
      document.getElementById('modalM2').click();
    }, 500);
  }


}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mexicanisimo',
  templateUrl: './mexicanisimo.component.html',
  styleUrls: ['./mexicanisimo.component.css']
})
export class MexicanisimoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

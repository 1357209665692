import { Component } from '@angular/core';
import {AppState, AppStateDeliver, AppStateModifiers, AppStateSuc, AppStateSuc2} from './app.reducers';
import {Store} from '@ngrx/store';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
declare  let gtag;
declare let fbq;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'client';

  constructor(private store: Store <AppState>,
              private storeDeliver: Store <AppStateDeliver>,
              private storeModifiers: Store <AppStateModifiers>,
              private storeSuc: Store <AppStateSuc>,
              private storeSuc2: Store <AppStateSuc2>,
              private router: Router) {
    const navEndEvents$ = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      );

    navEndEvents$.subscribe((event: NavigationEnd) => {
      // google analitycs
      gtag('config', 'UA-169655963-1', {
        page_path: event.urlAfterRedirects
      });
    });

    // facebook pixel
    router.events.subscribe((y: NavigationEnd) => {
      if(y instanceof NavigationEnd) {
        fbq('track', 'PageView');
      }
    });


    // Redux refresh inicializar
    this.store.subscribe(refresh => {
      // this.contador = contador;
      // console.log(refresh);
    });

    // Redux type order inicializar
    this.storeDeliver.subscribe(typeOrder => {
     // console.log(typeOrder);
    });

    // Redux type modifiers inicializar
    this.storeModifiers.subscribe(typeModifiers => {
     // console.log(typeModifiers);
    });

    // Redux refrescar seleccionar sucursal inicializar
    this.storeSuc.subscribe(refresh => {
     // console.log(refresh);
    });

    // Redux refrescar seleccionar sucursal inicializar
    this.storeSuc.subscribe(refresh => {
     // console.log(refresh);
    });

    // Redux refrescar seleccionar sucursal inicializar
    this.storeSuc2.subscribe(refresh2 => {
     // console.log(refresh2);
    });
  }

  onActivate(event) {
    window.scroll(0, 0);

  }
}

import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SucursalService {

  constructor(private  http: HttpClient) { }

  getSucursales() {
    const headers = new HttpHeaders({'content-Type': 'application/json'});
    return this.http.get(environment.BASE_API + '/sucursales', {headers});
  }


}

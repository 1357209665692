import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import * as $ from 'jquery';
import {NgxIzitoastService} from 'ngx-izitoast';
import {OrdersService} from '../../services/orders/orders.service';
import {Store} from '@ngrx/store';
import {AppStateModifiers} from '../../app.reducers';
import {NotRefreshAction, NotRefreshModifiers, RefreshAction, RefreshModifiers} from '../../../redux/ordenes.action';
import {Router} from '@angular/router';
@Component({
  selector: 'app-order-modifiers',
  templateUrl: './order-modifiers.component.html',
  styleUrls: ['./order-modifiers.component.css']
})
export class OrderModifiersComponent implements OnInit, OnChanges {
  @Input() idProduct: number;
  @Input() product: number;
  resp: any;

  listGroups: any[] = [];
  listOrderModifiers: any[] = []; // aqui se guardan los modificadores del id del productoOrder
  private prevSelected: any;
  radio: string;

  constructor(private ordersService: OrdersService,
              public iziToast: NgxIzitoastService,
              private storeModifiers: Store <AppStateModifiers>,
              private router: Router) {

    // Redux listenig type Action
    this.storeModifiers.subscribe(refresh => {
      this.resp = refresh;
      console.log(this.resp);

      if (this.resp.modifiers === 3 && this.idProduct  != undefined) { // si llega el estado tres recargamos las ordenes del modificador

          this.getProductModifiers(this.idProduct); // <-- recargar los modificadores de la orden cuando se actualiza un modificador
      }
    });
  }

  ngOnInit() {

    if (this.product == undefined) {

    } else {
      // alert(this.idProduct);
      this.getModificadores(this.product);
    }

  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.product.currentValue == undefined) {

    } else {
      console.log('change modifiers' + this.idProduct);
      this.getModificadores(this.product);
      this.getProductModifiers(this.idProduct);
    }

    // You can also use categoryId.previousValue and
    // categoryId.firstChange for comparing old and new values

  }

  getModificadores(idProduct: number) {
    this.ordersService.getModifiers(idProduct).subscribe(
      res => {
        this.resp = res;
        this.listGroups = this.resp.content;
        console.log(this.listGroups);
        // 0 es opción única
        // 1 es multiple
        // this.groupModifiers(this.listModifiers);
        // llamar la descripción del producto
      },
      err => {
        console.error(err);
        this.iziToast.show(
          {
            title: 'Lo sentimos, pero no se pudieron cargar los modificadores correctamente.<br> revisa tu conexión e intenta de nuevo.',
            titleColor: '#f8f8ff',
            color: '#ff5131',
            position: 'topRight'
          });
      }
    );
  }


  getProductModifiers(idOrderProduct: number) {
    this.ordersService.getProductModifiers(idOrderProduct).subscribe(
      res => {
        this.resp = res;
        this.listOrderModifiers = this.resp.content;
        console.log(this.resp);
      },
      err => {
        console.error(err);
        this.iziToast.show(
          {
            title: 'Lo sentimos, pero no se pudieron cargar los modificadores correctamente.<br> revisa tu conexión e intenta de nuevo.',
            titleColor: '#f8f8ff',
            color: '#ff5131',
            position: 'topRight'
          });
      }
    );
  }

  onItemChangeRadio(idModificador: number, indiceGroups: number, evt: any) {
    const target = evt.target;

    if (target.checked) {
      console.log(JSON.stringify(this.listGroups[indiceGroups].modificadores));

      console.log(idModificador, this.idProduct);
      this.prevSelected = target;
      this.postProductModifiers(idModificador, this.idProduct, 0 ); // <- 0 es para agregar
      // verificar que si se haya agregado
      if (!this.findModifier(idModificador)) {
        // redux recargar
        const action = new RefreshModifiers();
        this.storeModifiers.dispatch(action);
        // redux dejar de recargar
        const action2 = new NotRefreshModifiers();
        this.storeModifiers.dispatch(action2);
      }

    }

  }
  onItemChangeSelect(idModificador, evt: any) {
    const target = evt.target;

    if (target.checked) {
      console.log(idModificador, this.idProduct);
      this.prevSelected = target;
      this.postProductModifiers(idModificador, this.idProduct, 0 ); // <- 0 es para agregar

      // verificar que si se haya agregado
      if (!this.findModifier(idModificador)) {
        // redux recargar
        const action = new RefreshModifiers();
        this.storeModifiers.dispatch(action);
        // redux dejar de recargar
        const action2 = new NotRefreshModifiers();
        this.storeModifiers.dispatch(action2);
      }

    } else {
      this.postProductModifiers(idModificador, this.idProduct, 1 ); // <- 1 es para eliminar
      // verificar que si se haya eliminado
      if (this.findModifier(idModificador)) {
        // redux recargar
        const action = new RefreshModifiers();
        this.storeModifiers.dispatch(action);
        // redux dejar de recargar
        const action2 = new NotRefreshModifiers();
        this.storeModifiers.dispatch(action2);
      }
    }

  }
  postProductModifiers(idModif: number, idOrderProduct: number, opcion: number) {
    const formData = new FormData();

    formData.append('opcion', opcion.toString()); // <- 0 es para agregar
    formData.append('modificador', idModif.toString());

    this.ordersService.postProductModifiers(formData, idOrderProduct).subscribe(
      res => {
        this.resp = res;
        if (this.resp.success) {
          console.log('response:' + JSON.stringify(this.resp));
          // <- 0 es para agregar <- 1 es para eliminar
          const Msg = opcion === 0 ? 'Se ha agregado correctamente' : 'Se ha elimiando  correctamente';
          const MsgColor = opcion === 0 ? '#4CAF50' : '#535353';
          if (opcion == 0) {
            this.iziToast.show(
              {

                title: Msg,
                titleColor: '#f8f8ff',
                color: MsgColor,
                position: 'topRight',
                closeOnEscape: true,

              });
          }

          // redux recargar
          const action = new RefreshModifiers();
          this.storeModifiers.dispatch(action);
          // redux dejar de recargar
          const action2 = new NotRefreshModifiers();
          this.storeModifiers.dispatch(action2);


        } else {

          this.iziToast.show(
            {
              title: 'Lo sentimos, algo salio mal al agregar el modificador.<br> revisa tu conexión e intenta de nuevo.',
              titleColor: '#f8f8ff',
              color: '#ff5131',
              position: 'topRight'
            });
        }
      },
      err => {
        console.error(err);
        const Msg = opcion === 0 ? 'Lo sentimos, no se pudo agregar el modificador' : 'Lo sentimos, no se pudo eliminar el modificador';
        this.iziToast.show(
          {
            title: Msg + '.\n revisa tu conexión e intenta de nuevo.',
            titleColor: '#f8f8ff',
            color: '#ff5131',
            position: 'topRight'
          });
      }
    );


  }

  findModifier(idModifier: number) {
    for (let iModifier = 0; iModifier < this.listOrderModifiers.length; iModifier++) {
      // console.log(this.listOrderModifiers[iModifier].id);
      if (idModifier == this.listOrderModifiers[iModifier].modificador) { // si encontramos el modificador lo seleccionamos
        return true;
      }
    }
    return false;
  }

  validModifiers() {
    let  valid: boolean;
    console.log(this.listGroups);

    for (let i = 0; i < this.listGroups.length; i++) {
      valid = false;
      for (let j = 0; j < this.listGroups[i].modificadores.length; j++) {
        console.log(this.listGroups[i].titulo);
        console.log(this.listGroups[i].modificadores[j]);
        console.log(this.findModifier(this.listGroups[i].modificadores[j].id));
        if (this.findModifier(this.listGroups[i].modificadores[j].id || this.listGroups[i].modificadores[j].tipo == 1 )){
          valid = true;
        }
      }
      if (!valid) {
        this.iziToast.show(
          {
            title: this.listGroups[i].titulo + '\n para poder comprar.',
            titleColor: '#f8f8ff',
            color: '#ff5131',
            position: 'topRight'
          });
        return;
      }
    }

    this.router.navigate(['/orden']);



  }

}

import { Component, OnInit } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {OrdersService} from '../../services/orders/orders.service';
import {NgxIzitoastService} from 'ngx-izitoast';
import {Store} from '@ngrx/store';
import {AppState, AppStateDeliver} from '../../app.reducers';
import * as $ from 'jquery';
import {DeliverAction, NotRefreshAction, RefreshAction} from '../../../redux/ordenes.action';
import {SucursalService} from '../../services/sucursal/sucursal.service';
import {MapsService} from '../../services/maps/maps.service';
@Component({
  selector: 'app-mi-orden',
  templateUrl: './mi-orden.component.html',
  styleUrls: ['./mi-orden.component.css'],
  providers: [NgbModalConfig, NgbModal]// add NgbModalConfig and NgbModal to the component providers
})
export class MiOrdenComponent implements OnInit {


  resp: any;
  now: boolean;
  hourStart: string;
  hourEnd: string;
  minutesTolerancyStart: number;
  minutesTolerancyEnd: number;
  listSucursales: any[] = [];
  emailSent: string;
  textSuccessVisible: boolean;
  spinnerSendingVisible: boolean;
  directionSuc: string;
  order: any = {
    type_order: 1,
    delivered_at: '',
    scheduled: '',
    note: '',
    address1: '',
    address2: '',
    zip_code: '',
    city: '',
    name: '',
    email: '',
    telephone: '',
    cel: '',
    latitud: '',
    longitud: '',
    sucursal: ''

  };
  location: any = {
    lat: '',
    lng: ''
  };
  geolocationPosition: any;
  locationChoose = false;
  public origin: any;
  public destination: any;
  public sucursalNear: number;

  constructor(private ordersService: OrdersService,
              private  sucursalService: SucursalService,
              public iziToast: NgxIzitoastService,
              private store: Store <AppState>,
              private storeDeliver: Store <AppStateDeliver>,
              private modalService: NgbModal,
              private map: MapsService) {
    this.now = false;
    this.order.scheduled = 0; // <- send now
    this.textSuccessVisible = false;
    this.emailSent = '';
    this.spinnerSendingVisible = false; // <- cuando arranque que no se avisible el espiner de enviando orden

    this.sucursalNear = 0;


    this.minutesTolerancyStart = 20; // minutos de tolerancia para entrar 20 minutos depues de abrir para tener listas las ordenes
    this.minutesTolerancyEnd = 20; // minutos antes de cerrar
    // Redux Reload typeOrder
    this.storeDeliver.subscribe(typeOrder => {
      this.resp = typeOrder;
      this.order.type_order = this.resp.type;
      // if (this.resp.orden === true) {
      //   this.order.type_order=
      // }
    });
  }

  ngOnInit() {
    if (localStorage.getItem('selectSuc') == null) {
      this.getLocation();
      if (window.navigator && window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition(
          position => {
            this.geolocationPosition = position,
              console.log(position);
            this.location.lat = this.geolocationPosition.coords.latitude;
            this.location.lng = this.geolocationPosition.coords.longitude;
            this.getSucursales();
            // this.origin = { lat: -98.92412266628679, lng: 19.955740284826145 }; // estas direcciones son para trazar la ruta
            // this.destination = { lat: 19.955410136121287, lng: -98.9235445050049 };
          },
          error => {
            switch (error.code) {
              case 1:
                console.log('Permission Denied');
                break;
              case 2:
                console.log('Position Unavailable');
                break;
              case 3:
                console.log('Timeout');
                break;
            }

          }
        );
      }
    } else {// ya tiene una sucursal selleccionada
      this.getSucursales();
    }
  }

  getSucursales() {
    // llamar el servicio periodos y guardarlo
    this.sucursalService.getSucursales().subscribe(
      res => {
        this.resp = res;
        this.listSucursales = this.resp.content;
        // si no tiene una sucursal seleccionada recomendarle la más cercana
        if (localStorage.getItem('selectSuc') == null) {
          for (let i = 0; i < this.listSucursales.length; i++) {
            this.selectSucusalNear(i);
          }
        } else {
          // tslint:disable-next-line:radix
          this.order.sucursal = parseInt(localStorage.getItem('selectSuc')) ;
          this.defineDirection();
        }

        console.log('sucursales' + JSON.stringify( this.listSucursales));
      },
      err => {
        console.error(err);
        this.iziToast.show(
          {
            title: 'Lo sentimos, algo salio mal al cargar las sucursales.<br> revisa tu conexión e intenta de nuevo.',
            titleColor: '#f8f8ff',
            color: '#ff5131',
            position: 'topRight'
          });
      }
    ); // fin del subscribe

  }

  selectSucusalNear(indiceSuc: number) {

    const lat2 = this.listSucursales[indiceSuc].latitud;
    const lon2 = this.listSucursales[indiceSuc].longitud;
    const lat1 = this.location.lat;
    const lon1 = this.location.lng;

    const R = 6371; // km

    const x1 = lat2 - lat1;
    const dLat = this.toRad(x1);
    const x2 = lon2 - lon1;
    const dLon = this.toRad(x2);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.toRad(lat1)) * Math.cos(this.toRad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    console.log(this.sucursalNear);
    if (this.sucursalNear != 0) {
      // calcular la mayor
      if (d <= this.sucursalNear) {
        this.sucursalNear = d;
        this.order.sucursal =  this.listSucursales[indiceSuc].id;
        // definir dirección
        this.directionSuc = this.listSucursales[indiceSuc].city + ' ' +
          this.listSucursales[indiceSuc].state + ' ' +
          this.listSucursales[indiceSuc].address1;
      } else {

      }

    } else {
      this.sucursalNear = d;
      this.order.sucursal =  this.listSucursales[indiceSuc].id;
      // definir dirección
      this.directionSuc = this.listSucursales[indiceSuc].city + ' ' +
        this.listSucursales[indiceSuc].state + ' ' +
        this.listSucursales[indiceSuc].address1;
    }
  }

  toRad(x: number) {
    return x * Math.PI / 180;
  }

  scheduled() { // programar el envio

    const bool = this.now;

    console.log(this.now + ' booleano ' + bool.toString());
    // tslint:disable-next-line:triple-equals
    if ('true' === bool.toString()) { // programar un pedido

      this.calcHorario();
      this.order.scheduled = 1;
    }

    if ('false' === bool.toString()) { // pedir ahora
      this.order.scheduled = 0; // <- scheduled 0

      //


      // definir hora inicial y hora final
      this.hourStart = this.listSucursales[this.order.sucursal].horario[0].open;
      this.hourEnd = this.listSucursales[this.order.sucursal].horario[0].close;

      console.log('hourStart ' +  this.hourStart);
      console.log('hourEnd ' +  this.hourEnd);

      this.hourStart = this.minutesToHours(this.hoursToMinutes(this.hourStart) + this.minutesTolerancyStart );

      this.hourEnd = this.minutesToHours(this.hoursToMinutes(this.hourEnd) - this.minutesTolerancyEnd);


      // // si mi hora actual esta dentro del horario aumentale 20 minutos
      const today = new Date();
      const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
      if ( this.hoursToMinutes(time) > this.hoursToMinutes(this.hourStart) &&
        this.hoursToMinutes(time) < this.hoursToMinutes(this.hourEnd) ) {

      } else {
        this.iziToast.show({
          image: './assets/ico/error.png',
          title:  'Lo sentimos pero la sucursal no puede tomar un pedido<br>' +
            'fuera del horario ' + this.hourStart + '-' + this.hourEnd + ', intenta mañana gracias.',
          position: 'topCenter',
          theme: 'dark',
          overlay: true

        });

        $('#when').focus();
      }



    }

  }

  buyNow() {
    const formData = new FormData();
    this.spinnerSendingVisible = true; // mostrar spinner de cargando

    formData.append('type_order', this.order.type_order );
    formData.append('scheduled', this.order.scheduled );
    formData.append('deliver_at', this.order.delivered_at );
    formData.append('note', this.order.note );
    formData.append('address1', this.order.address1 );
    formData.append('address2', this.order.address2 );
    formData.append('zip_code', this.order.zip_code );
    formData.append('city', this.order.city );
    formData.append('name', this.order.name );
    formData.append('email', this.order.email );
    formData.append('telephone', this.order.telephone );
    formData.append('cel', this.order.cel );
    formData.append('latitud', this.location.lat );
    formData.append('longitud', this.location.lng );
    formData.append('sucursal', this.order.sucursal.id );


    this.ordersService.sendOrder(formData).subscribe(
      res => {
        this.resp = res;
        this.spinnerSendingVisible = false; // <- ocultar spinner
        if (this.resp.success) {
          console.log('response:' + JSON.stringify(this.resp));
          // redux recargar
          const action = new RefreshAction();
          this.store.dispatch(action);
          // redux dejar de recargar
          const action2 = new NotRefreshAction();
          this.store.dispatch(action2);
          this.textSuccessVisible = true;
          this.emailSent = this.order.email;
          this.cleanModels();


        } else {

          this.iziToast.show({
            image: './assets/ico/error.png',
            title: this.resp.msg_error,
            position: 'topCenter',
            theme: 'dark',

          });
        }
      },
      err => {
        this.spinnerSendingVisible = false; // <- ocultar spinner
        console.error(err);
        this.iziToast.show(
          {
            title: 'Lo sentimos, algo salio mal al enviar tu orden.<br>revisa tu conexión e intenta de nuevo.',
            titleColor: '#f8f8ff',
            color: '#ff5131',
            position: 'topRight'
          });
      }
    );


  }

  cleanModels() {

    // this.order.type_order =1;
    const action = new DeliverAction();
    this.storeDeliver.dispatch(action);
    this.order.delivered_at = '';
    this.order.scheduled = 0;

    this.order.note = '';
    this.order.address1 = '';
    this.order.address2 = '';
    this.order.zip_code = '';
    this.order.city = '';
    this.order.name = '';
    this.order.email = '';
    this.order.telephone = '';
    this.order.telephone2 = '';
    // this.order.latitud = ''; latitud y longitud no debería limpiarla porque siempre es la misma
    // this.order.longitud = '';
    this.order.sucursal = '';
  }

  calcHorario() {

    // definir hora inicial y hora final
    this.hourStart = this.listSucursales[this.order.sucursal].horario[0].open;
    this.hourEnd = this.listSucursales[this.order.sucursal].horario[0].close;

    console.log('hourStart ' +  this.hourStart);
    console.log('hourEnd ' +  this.hourEnd);

    this.hourStart = this.minutesToHours(this.hoursToMinutes(this.hourStart) + this.minutesTolerancyStart );

    this.hourEnd = this.minutesToHours(this.hoursToMinutes(this.hourEnd) - this.minutesTolerancyEnd);


    // // si mi hora actual esta dentro del horario aumentale 20 minutos
    const today = new Date();
    const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    if ( this.hoursToMinutes(time) > this.hoursToMinutes(this.hourStart) &&
      this.hoursToMinutes(time) < this.hoursToMinutes(this.hourEnd) ) {

      this.hourStart = this.minutesToHours(this.hoursToMinutes(time) + this.minutesTolerancyStart );
    }

    // partir de una hora inicial o base
    this.order.delivered_at = this.hourStart;
    console.log('hourStart ' +  this.hourStart); // le sumo los 20 minutos
    console.log('hourEnd ' +  this.hourEnd); // le sumo los 20 minutos
  }

  defineDirection() {
    // puedo escribir la dirección
    // iSucursal.city+" "+iSucursal.state+" "+iSucursal.address1

    localStorage.setItem('selectSuc', this.order.sucursal.toString());
    // busca la dirección en la sucursales que tengan el mismo id y si la encuentra se detiene y  lo pinta
    for (let i = 0; i < this.listSucursales.length; i++) {
      // tslint:disable-next-line:triple-equals
      if (this.order.sucursal == this.listSucursales[i].id) {
        this.directionSuc = this.listSucursales[i].city + ' ' +
          this.listSucursales[i].state + ' ' +
          this.listSucursales[i].address1;
        // alert(this.idSucursalSelect + '==' + this.directionSuc);
        break;
      }
    }

  }

  openModal() {
    if (this.validations()) {
      document.getElementById('modal-bootstrap-3').click();
      this.textSuccessVisible = false;
    }

  }

  validations() {
    let valid = true;
    const empty = '';
    // valid name
    if (this.order.name.toString() == empty) {

      this.iziToast.show({
        image: './assets/ico/error.png',
        title: 'El nombre no puede estar vacío',
        position: 'topCenter',
        theme: 'dark',

      });
      valid = false;
      $('#name').focus();
      return ;
    }
    // valid email
    if (!this.validateEmail(this.order.email)) {// si el email no es valido

      this.iziToast.show({
        image: './assets/ico/error.png',
        title: 'Por favor ingresa una dirección de correo valida',
        position: 'topCenter',
        theme: 'dark',

      });
      $('#email').focus();
      valid = false;
      return ;
    }
    // valid cel
    if (this.order.cel.toString() == empty) {

      this.iziToast.show({
        image: './assets/ico/error.png',
        title: 'El número de teléfono celular no puede estar vacío',
        position: 'topCenter',
        theme: 'dark',

      });
      valid = false;
      $('#cel').focus();
      return ;
    }


    // valid cp
    if (this.order.zip_code.toString() == empty) {

      this.iziToast.show({
        image: './assets/ico/error.png',
        title: 'El código postal no puede estar vacío',
        position: 'topCenter',
        theme: 'dark',

      });
      valid = false;
      $('#zip_code').focus();
      return ;
    }

    const regexp = /^[0-9]{5}(?:-[0-9]{4})?$/;

    if (regexp.test(this.order.zip_code.toString())) {

    } else {
      this.iziToast.show({
        image: './assets/ico/error.png',
        title: 'El código postal invalido',
        position: 'topCenter',
        theme: 'dark',

      });
      valid = false;
      $('#zip_code').focus();
      return ;
    }


    // valid order now
    if (this.order.scheduled == 0) {
      this.hourStart = this.listSucursales[this.order.sucursal].horario[0].open;
      this.hourEnd = this.listSucursales[this.order.sucursal].horario[0].close;

      console.log('hourStart ' +  this.hourStart);
      console.log('hourEnd ' +  this.hourEnd);

      this.hourStart = this.minutesToHours(this.hoursToMinutes(this.hourStart) + this.minutesTolerancyStart );

      this.hourEnd = this.minutesToHours(this.hoursToMinutes(this.hourEnd) - this.minutesTolerancyEnd);


      // // si mi hora actual esta dentro del horario aumentale 20 minutos
      const today = new Date();
      const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
      if ( this.hoursToMinutes(time) > this.hoursToMinutes(this.hourStart) &&
        this.hoursToMinutes(time) < this.hoursToMinutes(this.hourEnd) ) {

      } else {
        this.iziToast.show({
          image: './assets/ico/error.png',
          title:  'Lo sentimos pero la sucursal no puede tomar un pedido<br>' +
            'fuera del horario ' + this.hourStart + ' - ' + this.hourEnd + ', intenta mañana gracias.',
          position: 'topCenter',
          theme: 'dark',
          overlay: true

        });
        valid = false;
        $('#when').focus();
        return ;
      }
    }

    return valid;
  }



  getLocation() {
    this.map.getLocation().subscribe(
      res => {
        console.log(res);
        this.location.lat = res.latitude;
        this.location.lng = res.longitude;
        this.getSucursales();
      }
    );
  }
  onChooseLocation(event) { // es para mover el marcador del mapa de gmaps
    this.location.lat = event.coords.lat;
    this.location.lng = event.coords.lng;
    this.locationChoose = true;
  }

  validateEmail(email: string) {
    // tslint:disable-next-line:max-line-length
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  validHour() {
    console.log('key' + this.order.delivered_at);
    //
    if ( this.order.delivered_at < this.hourStart) {
      this.iziToast.show({
        image: './assets/ico/error.png',
        title:  'Lo sentimos pero la sucursal no puede programar un pedido<br>' +
          'antes de las ' + this.hourStart + ' porque necesitan un tiempo<br>' +
          'minimo de ' + this.minutesTolerancyStart + ' minutos para preparar tu orden, Gracias.',
        position: 'topCenter',
        theme: 'dark',
        overlay: true

      });
      this.order.delivered_at = this.hourStart;
      $('#deliver-at').focus();
    }
    if  ( this.order.delivered_at > this.hourEnd) {
      this.iziToast.show({
        image: './assets/ico/error.png',
        title:  'Lo sentimos pero la sucursal no puede programar un pedido tan tarde,<br>' +
          'ya que el servicio de pedidos cierra a las ' + this.hourEnd + '<br> intente' +
          'programarlo antes de la hora antes mencionada, Gracias.',
        position: 'topCenter',
        theme: 'dark',
        overlay: true

      });
      this.order.delivered_at = this.hourEnd;
      $('#deliver-at').focus();
    }

  }

  validCel() {

    if (this.order.cel.toString().length > 10) {

      this.iziToast.show({
        image: './assets/ico/error.png',
        title: 'El numero de teléfono celular no puede ser tan largo',
        position: 'topCenter',
        theme: 'dark',
      });
      // this.order.cel = this.order.cel.substring(0, 12);
      console.log(this.order.cel.toString().substring(0, 10));

      this.order.cel = this.order.cel.toString().substring(0, 10);
      $('#cel').focus();

    }

  }
  validPhone() {

    if (this.order.telephone.toString().length > 10) {

      this.iziToast.show({
        image: './assets/ico/error.png',
        title: 'El numero de teléfono no puede ser tan largo',
        position: 'topCenter',
        theme: 'dark',
      });
      // this.order.cel = this.order.cel.substring(0, 12);
      console.log(this.order.telephone.toString().substring(0, 10));
      this.order.telephone = this.order.telephone.toString().substring(0, 10);
      $('#phone').focus();

    }

  }
  validCP() {
    if (this.order.zip_code.toString().length > 5) {

      this.iziToast.show({
        image: './assets/ico/error.png',
        title: 'El código Postal no puede ser tan largo',
        position: 'topCenter',
        theme: 'dark',
        overlay: true
      });
      // this.order.cel = this.order.cel.substring(0, 12);
      // console.log(this.order.telephone.toString().substring(0, 10));
      // this.order.telephone = this.order.telephone.toString().substring(0, 10);
      $('#zip_code').focus();

    }
  }
  hoursToMinutes(hour: string) {
    const hoursStart = parseInt(hour.substring(0, 2));
    const minutesStart = parseInt(hour.substring(3, 5));

    return Math.floor(hoursStart * 60) + minutesStart;
  }

  minutesToHours(minutes: number) {
    const hoursStart: number = Math.floor( minutes / 60);
    const minutesStart: number = minutes % 60;
    let hoursStartS: string;
    let minutesStartS: string;
    // aumentarle las horas 2 cifras
    if (hoursStart < 10) {
      hoursStartS = '0' + hoursStart;
    } else {
      hoursStartS = '' + hoursStart;
    }
    if (minutesStart < 10) {// OSEA A DOS CIFRAS
      minutesStartS = '0' + minutesStart;
    } else {
      minutesStartS = '' + minutesStart;
    }

    return hoursStartS + ':' + minutesStartS + ':' + '00';



  }

}

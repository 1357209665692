
import { Component, OnInit } from '@angular/core';
import {SucursalService} from '../../services/sucursal/sucursal.service';
import {NgxIzitoastService} from 'ngx-izitoast';
import {ProductsService} from '../../services/products/products.service';
import {MapsService} from '../../services/maps/maps.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {AppStateSuc, AppStateSuc3, AppStateSuc4} from '../../app.reducers';
import * as $ from 'jquery';
import { NotRefreshSucAction4, RefreshSucAction4} from '../../../redux/ordenes.action';


@Component({
  selector: 'app-evento',
  templateUrl: './evento.component.html',
  styleUrls: ['./evento.component.scss']
})
export class EventoComponent implements OnInit {

  idSucursalSelect: number;
  listSucursales: any[] = [];
  resp: any;
  geolocationPosition: any;
  location: any = {
    lat: '',
    lng: ''
  };
  sucursalNear: number;
  directionSuc: string;
  constructor(private  sucursalService: SucursalService,
              public iziToast: NgxIzitoastService,
              private  productsService: ProductsService,
              private map: MapsService,
              private router: Router,
              private actRoute: ActivatedRoute,
              private storeSuc4: Store <AppStateSuc4>) {
    console.log(this.router.url);
  }

  ngOnInit() {

    // si no tiene una sucursal seleccionada
    // asignarle ma más cercana
    if (localStorage.getItem('selectSuc') == null) {
      this.getLocation();
      if (window.navigator && window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition(
          position => {
            this.geolocationPosition = position,
              console.log(position);
            this.location.lat = this.geolocationPosition.coords.latitude;
            this.location.lng = this.geolocationPosition.coords.longitude;
            this.getSucursales();
          },
          error => {
            switch (error.code) {
              case 1:
                console.log('Permission Denied');
                break;
              case 2:
                console.log('Position Unavailable');
                break;
              case 3:
                console.log('Timeout');
                break;
            }

          }
        );
      }

    } else { // ya tiene una sucursal selleccionada
      this.getSucursales();
    }
  }
  getLocation() {
    this.map.getLocation().subscribe(
      res => {
        console.log(res);
        this.location.lat = res.latitude;
        this.location.lng = res.longitude;
        this.getSucursales();
      }
    );
  }

  getSucursales() {
    // llamar el servicio periodos y guardarlo
    this.sucursalService.getSucursales().subscribe(
      res => {
        this.resp = res;
        this.listSucursales = this.resp.content;
        // si no tiene una sucursal seleccionada recomendarle la más cercana
        if (localStorage.getItem('selectSuc') == null) {
          for (let i = 0; i < this.listSucursales.length; i++) {
            this.selectSucusalNear(i);
            localStorage.setItem('selectSuc', this.idSucursalSelect.toString());
          }
        } else {
          // tslint:disable-next-line:radix
          this.idSucursalSelect = parseInt(localStorage.getItem('selectSuc')) ;
          this.defineDirection();
        }

        // console.log('sucursales' + JSON.stringify( this.listSucursales));
      },
      err => {
        console.error(err);
        this.iziToast.show(
          {
            title: 'Lo sentimos, algo salio mal al cargar las sucursales.<br> revisa tu conexión e intenta de nuevo.',
            titleColor: '#f8f8ff',
            color: '#ff5131',
            position: 'topRight'
          });
      }
    ); // fin del subscribe

  }

  selectSucusalNear(indiceSuc: number) {

    const lat2 = this.listSucursales[indiceSuc].latitud;
    const lon2 = this.listSucursales[indiceSuc].longitud;
    const lat1 = this.location.lat;
    const lon1 = this.location.lng;

    const R = 6371; // km

    const x1 = lat2 - lat1;
    const dLat = this.toRad(x1);
    const x2 = lon2 - lon1;
    const dLon = this.toRad(x2);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.toRad(lat1)) * Math.cos(this.toRad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    console.log(this.sucursalNear);
    // tslint:disable-next-line:triple-equals
    if (this.sucursalNear != 0) {
      // calcular la mayor
      if (d <= this.sucursalNear) {
        this.sucursalNear = d;
        this.idSucursalSelect = this.listSucursales[indiceSuc].id;
        // definir dirección
        this.directionSuc = this.listSucursales[indiceSuc].city + ' ' +
          this.listSucursales[indiceSuc].state + ' ' +
          this.listSucursales[indiceSuc].address1;
      } else {

      }

    } else {
      this.sucursalNear = d;
      this.idSucursalSelect = this.listSucursales[indiceSuc].id;
      // definir dirección
      this.directionSuc = this.listSucursales[indiceSuc].city + ' ' +
        this.listSucursales[indiceSuc].state + ' ' +
        this.listSucursales[indiceSuc].address1;
    }
  }

  toRad(x: number) {
    return x * Math.PI / 180;
  }
  defineDirection() {
    // guardar la posición del indice de la sucursal  seleccionada
    localStorage.setItem('selectSuc', this.idSucursalSelect.toString());
    // busca la dirección en la sucursales que tengan el mismo id y si la encuentra se detiene y  lo pinta
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.listSucursales.length; i++) {
      // tslint:disable-next-line:triple-equals
      if (this.idSucursalSelect == this.listSucursales[i].id) {
        this.directionSuc = this.listSucursales[i].city + ' ' +
          this.listSucursales[i].state + ' ' +
          this.listSucursales[i].address1;
        // alert(this.idSucursalSelect + '==' + this.directionSuc);
        break;
      }
    }


    // redux recargar categorias cuando seleccione una sucursal
    const actionRefreshCat = new RefreshSucAction4();
    this.storeSuc4.dispatch(actionRefreshCat);
    // redux dejar de recargar
    const actionNotRefreshCat = new NotRefreshSucAction4();
    this.storeSuc4.dispatch(actionNotRefreshCat);

  }



  showDir() {
    $('#h3-suc').show();
    $('#select-suc').show();
    $('.direction-suc').show();
    $('.close-select-suc').show();
    $('.espacio-suc').show();
  }

  hideDir() {
    $('#h3-suc').hide();
    $('#select-suc').hide();
    $('.direction-suc').hide();
    $('.close-select-suc').hide();
    $('.espacio-suc').hide();
  }
}

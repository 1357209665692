import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import {SucursalService} from '../../services/sucursal/sucursal.service';
import {ContactService} from '../../services/contact/contact.service';
import {NgxIzitoastService} from 'ngx-izitoast';
@Component({
  selector: 'app-factura',
  templateUrl: './factura.component.html',
  styleUrls: ['./factura.component.css']
})
export class FacturaComponent implements OnInit {
  resp: any;
  listSucursales: any[] = [];
  spinnerSendingVisible: boolean;
  hideRedirectForm: boolean;
  invoice: any = {
    sucursal: '',
    nombre: '',
    rfc: '',
    email: '',
    telefono: '',
    calle: '',
    ciudad: '',
    estado: '',
    codigo_postal: '',
    folio: '',
    monto: 0,
    ticket: File = null,

  };
  public cListSuc: any = 0;
  public listSuc: any[];

  constructor(private sucursalService: SucursalService,
              private contactService: ContactService,
              public iziToast: NgxIzitoastService) {
            this.hideRedirectForm = true;
  }

  ngOnInit() {
    this.getSucursales();
    this.spinnerSendingVisible = false;
    this.invoice.sucursal = '-99';
    this.listSuc = [
      { label: "MRC CHURUBUSCO", value: "http://www.facturar-t.com/PortalM_4.0/" },
      { label: "MRC COACALCO", value: "https://madero.pv1.mx/factura" },
      { label: "MRC CUAUTITLAN IZCALLI", value: "https://madero.pv1.mx/factura" },
      { label: "MRC IRAPUATO", value: "http://www.facturar-t.com/PortalM_4.0/" },
      { label: "MRC LERMA", value: "https://madero.pv1.mx/factura" },
      { label: "MRC MADERO", value: "http://www.facturar-t.com/PortalM_4.0/" },
      { label: "MRC METEPEC", value: "https://madero.pv1.mx/factura" },
      { label: "MRC SAN JAVIER", value: "https://madero.pv1.mx/factura" },
      { label: "MRC TULA", value: "https://madero.pv1.mx/factura" },
      { label: "MRC VILLAS", value: "https://madero.pv1.mx/factura" },
      { label: "MRC ZARAGOZA", value: "https://madero.pv1.mx/factura" },
    ];
  }

  redirectSuc() {
    if(this.cListSuc != "0") window.open(this.cListSuc, '_blank');
  }

  changeSuc() {
    // buscar si es lerma y redirigirlo al sistema
    // sucursal AV. MADERO
    if (this.invoice.sucursal == '1') {
      document.getElementById('modal-iframe').click();
      // tslint:disable-next-line:max-line-length
      window.open('http://www.facturamos.com.mx/MRCMADERO', '_blank');
      this.hideRedirectForm = true;
      return;
    }
    // villas de pachuca
    if (this.invoice.sucursal == '2') {
      document.getElementById('modal-iframe').click();
      // tslint:disable-next-line:max-line-length
      window.open('http://www.facturamos.com.mx/MRCVILLAS', '_blank');
      this.hideRedirectForm = true;
      return;
    }

    if (this.invoice.sucursal == '3') {
      document.getElementById('modal-iframe').click();
      // tslint:disable-next-line:max-line-length
      window.open('https://www.facturamos.com.mx/MRCVALLE', '_blank');
      this.hideRedirectForm = true;
      return;
    }
    if (this.invoice.sucursal == '4') {
      document.getElementById('modal-iframe').click();
      // tslint:disable-next-line:max-line-length
      window.open('http://www.facturamos.com.mx/MRCTULA', '_blank');
      this.hideRedirectForm = true;
      return;
    }
    if (this.invoice.sucursal == '5') {
      document.getElementById('modal-iframe').click();
      // tslint:disable-next-line:max-line-length
      window.open('http://www.facturamos.com.mx/MRCCHURUBUSCO', '_blank');
      this.hideRedirectForm = true;
      return;
    }
    if (this.invoice.sucursal == '6') {
      document.getElementById('modal-iframe').click();
      // tslint:disable-next-line:max-line-length
      window.open('http://www.facturamos.com.mx/MRCZARAGOZA', '_blank');
      this.hideRedirectForm = true;
      return;
    }
    if (this.invoice.sucursal == '7') {
      document.getElementById('modal-iframe').click();
      // tslint:disable-next-line:max-line-length
      window.open('https://www.facturamos.com.mx/MRCCOACALCO', '_blank');
      this.hideRedirectForm = true;
      return;

    }

    if (this.invoice.sucursal == '8') {
      // 8 es el id de LErma
      document.getElementById('modal-iframe').click();
      // tslint:disable-next-line:max-line-length
      window.open('https://www.facturamos.com.mx/(S(0mvelwusd0ifznuoqoznpp45))/App_Resource/FEWTicketE_v33.dll/FEWTicketE_v33.wfrmTicketE.aspx?TicketE=MRCLERMA', '_blank');
      this.hideRedirectForm = true;
      return;
    }

    if (this.invoice.sucursal == '9') {
      // 8 es el id de LErma
      document.getElementById('modal-iframe').click();
      // tslint:disable-next-line:max-line-length
      window.open('https://www.facturamos.com.mx/MRCCUAUTITLAN', '_blank');
      this.hideRedirectForm = true;
      return;
    }
    if (this.invoice.sucursal == '10') {
      // 8 es el id de LErma
      document.getElementById('modal-iframe').click();
      // tslint:disable-next-line:max-line-length
      window.open('http://www.facturamos.com.mx/MRCMETEPEC', '_blank');
      this.hideRedirectForm = true;
      return;
    }
    if (this.invoice.sucursal == '11') {
      document.getElementById('modal-iframe').click();
      // tslint:disable-next-line:max-line-length
      window.open('https://www.facturamos.com.mx/MRCIRAPUATO', '_blank');
      this.hideRedirectForm = true;
      return;
    }

    this.hideRedirectForm = false;

  }
  sendInvoice() {
    if (this.validations()) {
      // console.log(this.invoice);
      this.postInvoice();
    }
  }
  postInvoice() {
    const formData = new FormData();
    this.spinnerSendingVisible = true; // mostrar spinner de cargando
    formData.append('sucursal', this.invoice.sucursal );
    formData.append('nombre', this.invoice.nombre );
    formData.append('rfc', this.invoice.rfc );
    formData.append('email', this.invoice.email);
    formData.append('telefono', this.invoice.telefono);

    formData.append('calle', this.invoice.calle);
    formData.append('ciudad', this.invoice.ciudad);
    formData.append('estado', this.invoice.estado);
    formData.append('codigo_postal', this.invoice.codigo_postal);

    formData.append('folio', this.invoice.folio);
    formData.append('monto', this.invoice.monto);
    formData.append('ticket', this.invoice.ticket );



    this.contactService.sendInvoice(formData).subscribe(
      res => {
        this.resp = res;
        // this.spinnerSendingVisible = false; // <- ocultar spinner
        if (this.resp.success) {
          console.log('response:' + JSON.stringify(this.resp));

          this.cleanModels();
          this.spinnerSendingVisible = false; // <- ocultar spinner
          this.iziToast.show(
            {
              title: 'Muchas Gracias se han enviado tu solicitud de factura',
              titleColor: '#f8f8ff',
              color: '#4CAF50',
              position: 'topRight',
              closeOnEscape: true,

            });

        } else {
          this.spinnerSendingVisible = false; // <- ocultar spinner
          this.iziToast.show({
            image: './assets/ico/error.png',
            title: this.resp.msg_error,
            position: 'topCenter',
            theme: 'dark',

          });
        }
      },
      err => {
        this.spinnerSendingVisible = false; // <- ocultar spinner
        console.error(err);
        this.iziToast.show(
          {
            title: 'Lo sentimos, algo salio mal al enviar su formulario de facturación.<br>revisa tu conexión e intenta de nuevo.',
            titleColor: '#f8f8ff',
            color: '#ff5131',
            position: 'topRight'
          });
      }
    );


  }

  getSucursales() {
    // llamar el servicio periodos y guardarlo
    this.sucursalService.getSucursales().subscribe(
      res => {
        this.resp = res;
        this.listSucursales = this.resp.content;

        console.log('sucursales' + JSON.stringify( this.listSucursales));
      },
      err => {
        console.error(err);
        this.iziToast.show(
          {
            title: 'Lo sentimos, algo salio mal al cargar las sucursales.<br> revisa tu conexión e intenta de nuevo.',
            titleColor: '#f8f8ff',
            color: '#ff5131',
            position: 'topRight'
          });
      }
    ); // fin del subscribe

  }
  onImageChange(files: FileList) {
    this.invoice.ticket = files.item(0);
    console.log('File size', this.invoice.ticket.size);
    console.log('File type', this.invoice.ticket.type);
    // || this.invoice.ticket.type.toString() !== 'image/jpeg'
    if (this.invoice.ticket.type.toString() == 'image/png' ||
        this.invoice.ticket.type.toString() == 'image/jpeg' ) {


        if (this.invoice.ticket.size > 10485760) {
          this.iziToast.show({
            image: './assets/ico/error.png',
            title: 'El tamaño del archivo no puede ser mayor a 10 MB.',
            position: 'topCenter',
            theme: 'dark',

          });
          this.invoice.ticket = null;
          $('#image-ticket').focus();
          return;
        }

        console.log('File Change', this.invoice.ticket);

    } else {
      this.iziToast.show({
        image: './assets/ico/error.png',
        title: 'Solo puede cargar imagenes JPG, JPEG, Y PNG.',
        position: 'topCenter',
        theme: 'dark',

      });
      this.invoice.ticket = null;
      $('#image-ticket').focus();
      return;
    }


  }

  cleanModels() {
    this.invoice.sucursal = '-99';
    this.invoice.nombre = '';
    this.invoice.rfc = '';
    this.invoice.email = '';
    this.invoice.telefono = '';
    this.invoice.calle = '';
    this.invoice.ciudad = '';
    this.invoice.estado = '';
    this.invoice.codigo_postal = '';
    this.invoice.folio = '';
    this.invoice.monto = 0;
    this.invoice.ticket = null;



  }

  validations() {
    let valid = true;
    const empty = '';
    // valid sucursal
    if (this.invoice.sucursal.toString() == '-99') {

      this.iziToast.show({
        image: './assets/ico/error.png',
        title: 'Selecciona una sucursal por favor.',
        position: 'topCenter',
        theme: 'dark',

      });
      valid = false;
      $('#sucursal').focus();
      return ;
    }

    // valid name
    if (this.invoice.nombre.toString() == empty) {

      this.iziToast.show({
        image: './assets/ico/error.png',
        title: 'El nombre no puede estar vacío.',
        position: 'topCenter',
        theme: 'dark',

      });
      valid = false;
      $('#name').focus();
      return ;
    }


    // valid rfc
    if (this.invoice.rfc.toString() == empty) {

      this.iziToast.show({
        image: './assets/ico/error.png',
        title: 'El RFC no puede estar vacío.',
        position: 'topCenter',
        theme: 'dark',

      });
      valid = false;
      $('#rfc').focus();
      return ;
    }
    // valid email
    if (!this.validateEmail(this.invoice.email)) {// si el email no es valido

      this.iziToast.show({
        image: './assets/ico/error.png',
        title: 'Por favor ingresa una dirección de correo valida.',
        position: 'topCenter',
        theme: 'dark',

      });
      $('#email').focus();
      valid = false;
      return ;
    }
    // valid cel
    if (this.invoice.telefono.toString() == empty) {

      this.iziToast.show({
        image: './assets/ico/error.png',
        title: 'El número de teléfono celular no puede estar vacío.',
        position: 'topCenter',
        theme: 'dark',

      });
      valid = false;
      $('#cel').focus();
      return ;
    }
    // valid calle
    if (this.invoice.calle.toString() == empty) {

      this.iziToast.show({
        image: './assets/ico/error.png',
        title: 'El nombre de la calle no puede estar vacío.',
        position: 'topCenter',
        theme: 'dark',

      });
      valid = false;
      $('#calle').focus();
      return ;
    }
    // valid calle
    if (this.invoice.ciudad.toString() == empty) {

      this.iziToast.show({
        image: './assets/ico/error.png',
        title: 'El nombre de la ciudad no puede estar vacío.',
        position: 'topCenter',
        theme: 'dark',

      });
      valid = false;
      $('#ciudad').focus();
      return ;
    }

    // valid calle
    if (this.invoice.estado.toString() == empty) {

      this.iziToast.show({
        image: './assets/ico/error.png',
        title: 'El nombre del estado no puede estar vacío.',
        position: 'topCenter',
        theme: 'dark',

      });
      valid = false;
      $('#estado').focus();
      return ;
    }



    // valid cp
    if (this.invoice.codigo_postal.toString() == empty) {

      this.iziToast.show({
        image: './assets/ico/error.png',
        title: 'El código postal no puede estar vacío.',
        position: 'topCenter',
        theme: 'dark',

      });
      valid = false;
      $('#codigo').focus();
      return ;
    }


    // console.log(this.invoice.folio.toString());
    // valid folio
    if (this.invoice.folio.toString() == empty) {

      this.iziToast.show({
        image: './assets/ico/error.png',
        title: 'El folio no puede estar vacío.',
        position: 'topCenter',
        theme: 'dark',

      });
      valid = false;
      $('#folio').focus();
      return ;
    }
    //
    // // valid monto
    // if (this.invoice.monto.toString() == empty) {
    //
    //   this.iziToast.show({
    //     image: './assets/ico/error.png',
    //     title: 'El monto no puede estar vacío',
    //     position: 'topCenter',
    //     theme: 'dark',
    //
    //   });
    //   valid = false;
    //   $('#monto').focus();
    //   return ;
    // }

    if (!this.invoice.ticket) {
      this.iziToast.show({
        image: './assets/ico/error.png',
        title: 'Carga la foto del ticket por favor',
        position: 'topCenter',
        theme: 'dark',
        overlay: true
      });
      return ;
    }

    return valid;
  }

  validCP() {
    if (this.invoice.codigo_postal.toString().length > 5) {

      this.iziToast.show({
        image: './assets/ico/error.png',
        title: 'El código Postal no puede ser tan largo',
        position: 'topCenter',
        theme: 'dark',
        overlay: true
      });
      $('#zip_code').focus();
      this.invoice.codigo_postal = this.invoice.cel.substring(0, 6);

    }


  }
  validateEmail(email: string) {
    // tslint:disable-next-line:max-line-length
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  validateFolio() {

    //
    // alert(this.invoice.folio.toString().length);
    if (this.invoice.folio.toString().length > 9 ) {

      this.invoice.folio = parseInt(this.invoice.folio.toString().substr(0, 8), 10);
    }


  }


}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {OrdersService} from '../../services/orders/orders.service';
import {NgxIzitoastService} from 'ngx-izitoast';
@Component({
  selector: 'app-modifiers',
  templateUrl: './modifiers.component.html',
  styleUrls: ['./modifiers.component.css']
})
export class ModifiersComponent implements OnInit {
  public productId: string;
  public product: string;
  resp: any;
  listOrders: any[] = [];
  constructor(private actRoute: ActivatedRoute,
              private ordersService: OrdersService,
              public iziToast: NgxIzitoastService) {
    this.productId = this.actRoute.snapshot.params.id;
    this.product = this.actRoute.snapshot.params.product;

  }

  ngOnInit() {
   this.getOrderProducts();
  }

  getOrderProducts() {
    this.ordersService.getOrderProducts().subscribe(
      res => {
        this.resp = res;
        console.log(this.resp);
        this.listOrders = this.resp.content;

      },
      err => {
        console.error(err);
        this.iziToast.show(
          {
            title: 'Lo sentimos, algo salio mal al cargar los productos de tu orden.<br> revisa tu conexión e intenta de nuevo.',
            titleColor: '#f8f8ff',
            color: '#ff5131',
            position: 'topRight'
          });
      }
    );
  }
}

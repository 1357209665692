import { Component, OnInit } from '@angular/core';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {

  public BASE_URL: string;
  constructor() {
    this.BASE_URL = environment.BASE_URL;
  }

  ngOnInit() {
  }

}

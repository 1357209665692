import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {SucursalService} from '../../services/sucursal/sucursal.service';
import {NgxIzitoastService} from 'ngx-izitoast';
import {environment} from '../../../environments/environment';
import {ProductsService} from '../../services/products/products.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MapsService} from '../../services/maps/maps.service';
import * as $ from 'jquery';
import {Store} from '@ngrx/store';
import {AppStateSuc, AppStateSuc2} from '../../app.reducers';
import {
  NotRefreshAction,
  NotRefreshSucAction,
  NotRefreshSucAction2,
  RefreshAction,
  RefreshSucAction,
  RefreshSucAction2
} from '../../../redux/ordenes.action';
import {toInteger} from '@ng-bootstrap/ng-bootstrap/util/util';
declare var closeModalPromo: any;
declare var openModalPromo: any;
@Component({
  selector: 'app-promociones-y-paquetes',
  templateUrl: './promociones-y-paquetes.component.html',
  styleUrls: ['./promociones-y-paquetes.component.css']
})
export class PromocionesYPaquetesComponent implements OnInit, AfterViewInit {


  resp: any;
  listSucursales: any[] = [];
  listCategories: any[] = [];
  loadinglistCategories: boolean;
  BASE_URL = environment.BASE_URL;
  urlImageCat: string;
  indiceCatMobile: number;
  sucursalNear: number;
  idSucursalSelect: number;
  directionSuc: string;
  geolocationPosition: any;
  categoria: any = {
    id: 0,
    ruta: ''
  };
  location: any = {
    lat: '',
    lng: ''
  };

  constructor(private  sucursalService: SucursalService,
              public iziToast: NgxIzitoastService,
              private  productsService: ProductsService,
              private map: MapsService,
              private router: Router,
              private actRoute: ActivatedRoute,
              private storeSuc2: Store <AppStateSuc2>) {
    this.sucursalNear = 0;
    this.categoria.ruta = this.actRoute.snapshot.params.cat;
    // redux refresh categorias
    this.storeSuc2.subscribe(refreshSuc => {
      this.resp = refreshSuc;
      // console.log('*******'+JSON.stringify(this.resp) );
      if (this.resp.sucursal2 === true) {
        this.getCategories(); // <-- recargar categorias
        this.categoria.id = 0 ;
        this.categoria.ruta = '';
        // reinicialice los valores para que el ngChanges de app-productos-cat
        // se de cuenta que hay una nueva sucursal como si se agregar por primera vez
      }
    });
  }


  ngOnInit() {
    // redirigir a la categoria seleccionada

    this.getCategories();
    // si no tiene una sucursal seleccionada
    // asignarle ma más cercana
    if (localStorage.getItem('selectSuc') == null) {
      this.getLocation();
      if (window.navigator && window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition(
          position => {
            this.geolocationPosition = position,
              console.log(position);
            this.location.lat = this.geolocationPosition.coords.latitude;
            this.location.lng = this.geolocationPosition.coords.longitude;
            this.getSucursales();
          },
          error => {
            switch (error.code) {
              case 1:
                console.log('Permission Denied');
                break;
              case 2:
                console.log('Position Unavailable');
                break;
              case 3:
                console.log('Timeout');
                break;
            }

          }
        );
      }

    } else { // ya tiene una sucursal selleccionada
      this.getSucursales();
    }
  }

  ngAfterViewInit(): void {
    document.getElementById('abrir-sucursales').click();
  }

  getSucursales() {
    this.loadinglistCategories = false;
    // llamar el servicio periodos y guardarlo
    this.sucursalService.getSucursales().subscribe(
      res => {
        this.resp = res;
        this.listSucursales = this.resp.content;
        this.loadinglistCategories = true;
        // si no tiene una sucursal seleccionada recomendarle la más cercana
        if (localStorage.getItem('selectSuc') == null) {
          for (let i = 0; i < this.listSucursales.length; i++) {
            this.selectSucusalNear(i);
            localStorage.setItem('selectSuc', this.idSucursalSelect.toString());
          }
        } else {
          // tslint:disable-next-line:radix
          this.idSucursalSelect = parseInt(localStorage.getItem('selectSuc')) ;
          this.defineDirection(false);
        }

        // console.log('sucursales' + JSON.stringify( this.listSucursales));
      },
      err => {
        this.loadinglistCategories = true;
        console.error(err);
        this.iziToast.show(
          {
            title: 'Lo sentimos, algo salio mal al cargar las sucursales.<br> revisa tu conexión e intenta de nuevo.',
            titleColor: '#f8f8ff',
            color: '#ff5131',
            position: 'topRight'
          });
      }
    ); // fin del subscribe

  }

  defineDirection(closeModal: boolean) {
    // guardar la posición del indice de la sucursal  seleccionada
    localStorage.setItem('selectSuc', this.idSucursalSelect.toString());
    // busca la dirección en la sucursales que tengan el mismo id y si la encuentra se detiene y  lo pinta
    for (let i = 0; i < this.listSucursales.length; i++) {
      // tslint:disable-next-line:triple-equals
      if (this.idSucursalSelect == this.listSucursales[i].id) {
        this.directionSuc = this.listSucursales[i].city + ' ' +
          this.listSucursales[i].state + ' ' +
          this.listSucursales[i].address1;
        // alert(this.idSucursalSelect + '==' + this.directionSuc);
        break;
      }
    }


    // redux recargar categorias cuando seleccione una sucursal
    const actionRefreshCat2 = new RefreshSucAction2();
    this.storeSuc2.dispatch(actionRefreshCat2);
    // redux dejar de recargar
    const actionNotRefreshCat2 = new NotRefreshSucAction2();
    this.storeSuc2.dispatch(actionNotRefreshCat2);

    if (closeModal) {
      this.closeModalSucursales();
    }


  }

  getLocation() {
    this.map.getLocation().subscribe(
      res => {
        console.log(res);
        this.location.lat = res.latitude;
        this.location.lng = res.longitude;
        this.getSucursales();
      }
    );
  }

  selectSucusalNear(indiceSuc: number) {

    const lat2 = this.listSucursales[indiceSuc].latitud;
    const lon2 = this.listSucursales[indiceSuc].longitud;
    const lat1 = this.location.lat;
    const lon1 = this.location.lng;

    const R = 6371; // km

    const x1 = lat2 - lat1;
    const dLat = this.toRad(x1);
    const x2 = lon2 - lon1;
    const dLon = this.toRad(x2);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.toRad(lat1)) * Math.cos(this.toRad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    console.log(this.sucursalNear);
    // tslint:disable-next-line:triple-equals
    if (this.sucursalNear != 0) {
      // calcular la mayor
      if (d <= this.sucursalNear) {
        this.sucursalNear = d;
        this.idSucursalSelect = this.listSucursales[indiceSuc].id;
        // definir dirección
        this.directionSuc = this.listSucursales[indiceSuc].city + ' ' +
          this.listSucursales[indiceSuc].state + ' ' +
          this.listSucursales[indiceSuc].address1;
      } else {

      }

    } else {
      this.sucursalNear = d;
      this.idSucursalSelect = this.listSucursales[indiceSuc].id;
      // definir dirección
      this.directionSuc = this.listSucursales[indiceSuc].city + ' ' +
        this.listSucursales[indiceSuc].state + ' ' +
        this.listSucursales[indiceSuc].address1;
    }
  }

  toRad(x: number) {
    return x * Math.PI / 180;
  }

  getCategories() {
    // llamar el servicio periodos y guardarlo
    // tslint:disable-next-line:radix
    this.productsService.getCategoriesPromoSuc( parseInt(localStorage.getItem('selectSuc')) ).subscribe(
      res => {
        this.resp = res;
        console.log(this.resp);
        this.listCategories = this.resp.content;
        // agregarle la sección de promociones harcodeada
        // ya que va a ser estatica
        // const idPromocionesyPaquetes = -1000; // (this.listCategories[(this.listCategories.length - 1)].id) + (1) ;
        // const promocionesyPaquetes: any = {
        //   id: idPromocionesyPaquetes,
        //   name: 'Promociones y Paquetes' ,
        //   image: '../assets/img/menu/promociones.png'
        // };
        // this.listCategories.push(promocionesyPaquetes); // agregar promociones y paquetes


        // colocar la imagen
        if (this.listCategories.length > 0) {
          this.urlImageCat = this.listCategories[0].image;
          this.indiceCatMobile = 0;

          // si no tiene ninguna ruta
          if (this.actRoute.snapshot.params.cat === undefined) {
            // Todo redirecconar a la primera categoria
            this.selectCat(this.convertirCatRutas(this.listCategories[0].name));
          } else {
            this.categoria.ruta = this.actRoute.snapshot.params.cat;
            this.loadCat(this.listCategories);
          }


        }




      },
      err => {
        console.error(err);
        this.iziToast.show(
          {
            title: 'Lo sentimos, algo salio mal al cargar las categorías<br> revisa tu conexión e intenta de nuevo.',
            titleColor: '#f8f8ff',
            color: '#ff5131',
            position: 'topRight'
          });
      }
    ); // fin del subscribe

  }

  selectCat(catName: string) {

    this.router.navigateByUrl('/menu/promocionesypaquetes/' + catName);
    this.categoria.ruta = catName;
    console.log('actualizar ruta: ' + this.categoria.ruta);
    this.loadCat(this.listCategories);


  }

  loadCat(listCategories: any[]) {

    for (let i = 0; i < listCategories.length; i++) {

      console.log(this.convertirCatRutas(listCategories[i].name) + '==' + this.categoria.ruta);
      if (this.convertirCatRutas(listCategories[i].name) == this.categoria.ruta) {
        this.categoria.id = listCategories[i].id; // actualizamos el id

        this.urlImageCat = listCategories[i].image; // actualizamos la imágen
        break;
      }
    }
  }

  selectCatMobile() {
    document.getElementById('cat-opc-' + this.indiceCatMobile).click();
  }

  activateNav(name: string) {

    if (name == this.actRoute.snapshot.params.cat) {
      return 'active';
    }

    return '';
  }

  getLength(text: string) {
    return text.length;
  }

  showDir() {
    $('#h3-suc').show();
    $('#select-suc').show();
    $('.direction-suc').show();
    $('.close-select-suc').show();
    $('.espacio-suc').show();
  }

  hideDir() {
    $('#h3-suc').hide();
    $('#select-suc').hide();
    $('.direction-suc').hide();
    $('.close-select-suc').hide();
    $('.espacio-suc').hide();
  }

  convertirCatRutas(ruta: string) {
    let rutaFinal = '';
    // remplazar caracteres especiales
    ruta = ruta.replace(/á/g, 'a');
    ruta = ruta.replace(/é/g, 'e');
    ruta = ruta.replace(/í/g, 'i');
    ruta = ruta.replace(/ó/g, 'o');
    ruta = ruta.replace(/ú/g, 'u');
    ruta = ruta.replace(/ñ/g, 'n');
    ruta = ruta.toLowerCase() ; // convertir en minusculas y solo guardar caracteres de código ascii
    for (let i = 0; i < ruta.length; i++) {
      // console.log(ruta.charAt(i));
      if (ruta.charCodeAt(i) > 96 &&  ruta.charCodeAt(i) < 123) {
        // console.log( ruta.charAt(i));
        rutaFinal += ruta.charAt(i);
      }
    }

    return rutaFinal;
  }


  public openModalSucursales(): void {
    openModalPromo();
    console.log('open modal');
  }

  public closeModalSucursales(): void {
    closeModalPromo();
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-qr-restaurante',
  templateUrl: './qr-restaurante.component.html',
  styleUrls: ['./qr-restaurante.component.css']
})
export class QrRestauranteComponent implements OnInit {

  pdfSrc: string;
  urlQr: string;
  constructor(private router: Router, private sanitizer: DomSanitizer) {


  }

  ngOnInit() {

    //  console.log(this.router.url);
    this.urlQr = this.router.url;
    /*
      menuRestauranteV1 --> menuRestauranteCentro
      menuRestauranteV2 --> menuRestauranteBajio
    */

    switch (this.urlQr) {
      case '/qr-restaurante':
        this.pdfSrc = 'assets/pdf/menus/menuRestauranteV1.pdf';
        break;

      case '/qr-restaurante-tula':
        //this.pdfSrc = 'assets/pdf/menus/menuRestauranteV2.pdf';
        this.pdfSrc = 'assets/pdf/menus/menuRestauranteBotanas.pdf';
        break;

      case '/qr-restaurante-churubusco':
        this.pdfSrc = 'assets/pdf/menus/menuRestauranteV1.pdf';
        break;

      case '/qr-restaurante-madero':
        this.pdfSrc = 'assets/pdf/menus/menuRestauranteV1.pdf';
        break;

      case '/qr-restaurante-sanjavier':
        this.pdfSrc = 'assets/pdf/menus/menuRestauranteV1.pdf';
        break;

      case '/qr-restaurante-villas':
        this.pdfSrc = 'assets/pdf/menus/menuRestauranteV1.pdf';
        break;

      case '/qr-restaurante-irapuato':
        console.log('irapuato');
        //this.pdfSrc = 'assets/pdf/menus/menuRestauranteV2.pdf';
        this.pdfSrc = 'assets/pdf/menus/menuRestauranteBotanas.pdf';
        break;
      case '/qr-restaurante-queretaro': // Zaragoza
        this.pdfSrc = 'assets/pdf/menus/menuRestauranteBotanas.pdf';
        break;

      case '/qr-restaurante-coacalco':
        this.pdfSrc = 'assets/pdf/menus/menuRestauranteV1.pdf';
        break;


      case '/qr-restaurante-sendero':
        this.pdfSrc = 'assets/pdf/menus/menuRestauranteV1.pdf';
        break;

      case '/qr-restaurante-sanmarcos':
        this.pdfSrc = 'assets/pdf/menus/menuRestauranteV1.pdf';
        break;

      case '/qr-restaurante-metepec':
        this.pdfSrc = 'assets/pdf/menus/menuRestauranteV1.pdf';
        break;

      default:
        this.pdfSrc = 'assets/pdf/menus/menuRestauranteV1.pdf';
        break;
    }

    /*    const aux= 'http://docs.google.com/gview?embedded=true&url=' + environment.BASE_URL + this.pdfSrc;
        this.pdfSrc = aux*/

    // redireccionar si es android
    // para que no se este descargando cada vez que entre

    // similar behavior as an HTTP redirect
    if (!this.sistemOS()) {
      // alert(this.sistemOS());
      window.location.replace(this.pdfSrc);
    }

  }

  sistemOS(): boolean {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      // true for mobile device
      return false;
    } else {
      // false for not mobile device
      return true;
    }

  }

}

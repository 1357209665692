import {Component, Input, OnInit} from '@angular/core';
import {SucursalService} from '../../services/sucursal/sucursal.service';
import {NgxIzitoastService} from 'ngx-izitoast';
import {environment} from '../../../environments/environment';
import {ProductsService} from '../../services/products/products.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MapsService} from '../../services/maps/maps.service';
import * as $ from 'jquery';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  resp: any;
  listSucursales: any[] = [];
  listCategories: any[] = [];
  loadinglistCategories: boolean;

  BASE_URL = environment.BASE_URL;
  urlImageCat: string;
  sucursalNear: number;
  indiceSucursal: number;
  directionSuc: string;
  geolocationPosition: any;
  categoria: any = {
    id: 0,
    ruta: ''
  };
  location: any = {
    lat: '',
    lng: ''
  };

  constructor(private  sucursalService: SucursalService,
              public iziToast: NgxIzitoastService,
              private  productsService: ProductsService,
              private map: MapsService,
              private router: Router,
              private actRoute: ActivatedRoute) {

    this.sucursalNear = 0;


  }

  ngOnInit() {
    this.actRoute.paramMap.subscribe(params => {
      this.categoria.ruta = this.actRoute.snapshot.paramMap.get('cat');


      if (this.categoria.ruta) {
        this.validateCategories( this.categoria.ruta );
      } else {
        // cargamos el servicio de categorias
        this.productsService.getCategories().subscribe(
          res => {
            this.resp = res;
            this.listCategories = this.resp.content;
            if (this.listCategories.length > 0) {
              this.router.navigateByUrl('/menu/restaurante/' + this.convertirCatRutas(this.listCategories[0].name))
            }
          },
          err => {
            console.error(err);
            this.iziToast.show(
              {
                title: 'Lo sentimos, algo salio mal al cargar las categorías<br> revisa tu conexión e intenta de nuevo.',
                titleColor: '#f8f8ff',
                color: '#ff5131',
                position: 'topRight'
              });
          }
        ); // fin del subscribe
      }
    });


    // si no tiene una sucursal seleccionada
    // asignarle ma más cercana
    if (localStorage.getItem('selectSuc') == null) {
      this.getLocation();
      if (window.navigator && window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition(
          position => {
            this.geolocationPosition = position,
              console.log(position);
            this.location.lat = this.geolocationPosition.coords.latitude;
            this.location.lng = this.geolocationPosition.coords.longitude;
            this.getSucursales();
          },
          error => {
            switch (error.code) {
              case 1:
                console.log('Permission Denied');
                break;
              case 2:
                console.log('Position Unavailable');
                break;
              case 3:
                console.log('Timeout');
                break;
            }

          }
        );
      }

    } else { // ya tiene una sucursal selleccionada
      this.getSucursales();
    }
  }

  getSucursales() {
    this.loadinglistCategories = false;
    // llamar el servicio periodos y guardarlo
    this.sucursalService.getSucursales().subscribe(
      res => {
        this.resp = res;
        this.listSucursales = this.resp.content;
        this.loadinglistCategories = true;
        // si no tiene una sucursal seleccionada recomendarle la más cercana
        if (localStorage.getItem('selectSuc') == null) {
          for (let i = 0; i < this.listSucursales.length; i++) {
            this.selectSucusalNear(i);
            localStorage.setItem('selectSuc', this.indiceSucursal.toString());
          }
        } else {
          // tslint:disable-next-line:radix
          this.indiceSucursal = parseInt(localStorage.getItem('selectSuc')) ;
          this.defineDirection();
        }

        // console.log('sucursales' + JSON.stringify( this.listSucursales));
      },
      err => {
        this.loadinglistCategories = true;
        console.error(err);
        this.iziToast.show(
          {
            title: 'Lo sentimos, algo salio mal al cargar las sucursales.<br> revisa tu conexión e intenta de nuevo.',
            titleColor: '#f8f8ff',
            color: '#ff5131',
            position: 'topRight'
          });
      }
    ); // fin del subscribe

  }

  defineDirection() {
    // guardar la posición del indice de la sucursal  seleccionada
    localStorage.setItem('selectSuc', this.indiceSucursal.toString());
    // puedo escribir la dirección
    this.directionSuc = this.listSucursales[this.indiceSucursal].city + ' ' +
      this.listSucursales[this.indiceSucursal].state + ' ' +
      this.listSucursales[this.indiceSucursal].address1;

  }

  getLocation() {
    this.map.getLocation().subscribe(
      res => {
        console.log(res);
        this.location.lat = res.latitude;
        this.location.lng = res.longitude;
        this.getSucursales();
      }
    );
  }

  selectSucusalNear(indiceSuc: number) {

    const lat2 = this.listSucursales[indiceSuc].latitud;
    const lon2 = this.listSucursales[indiceSuc].longitud;
    const lat1 = this.location.lat;
    const lon1 = this.location.lng;

    const R = 6371; // km

    const x1 = lat2 - lat1;
    const dLat = this.toRad(x1);
    const x2 = lon2 - lon1;
    const dLon = this.toRad(x2);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.toRad(lat1)) * Math.cos(this.toRad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    console.log(this.sucursalNear);
    // tslint:disable-next-line:triple-equals
    if (this.sucursalNear != 0) {
      // calcular la mayor
      if (d <= this.sucursalNear) {
        this.sucursalNear = d;
        this.indiceSucursal = indiceSuc;
        // definir dirección
        this.directionSuc = this.listSucursales[indiceSuc].city + ' ' +
          this.listSucursales[indiceSuc].state + ' ' +
          this.listSucursales[indiceSuc].address1;
      } else {

      }

    } else {
      this.sucursalNear = d;
      this.indiceSucursal = indiceSuc;
      // definir dirección
      this.directionSuc = this.listSucursales[indiceSuc].city + ' ' +
        this.listSucursales[indiceSuc].state + ' ' +
        this.listSucursales[indiceSuc].address1;
    }
  }

  toRad(x: number) {
    return x * Math.PI / 180;
  }



  selectCat(catName: string) {
    catName = this.convertirCatRutas(catName);
    this.router.navigateByUrl('/menu/restaurante/' + catName);
    this.categoria.ruta = catName;
    console.log('actualizar ruta: ' + this.categoria.ruta);
  }

  private validateCategories(ruta: string) {

    // cargamos el servicio de categorias
    this.productsService.getCategories().subscribe(
      res => {
        this.resp = res;
        this.listCategories = this.resp.content;
        // buscar si la categoria que buscamos existe en la lista
        if (!this.existCat(this.listCategories)) {
          this.router.navigateByUrl('/eror404' );
        }
      },
      err => {
        console.error(err);
        this.iziToast.show(
          {
            title: 'Lo sentimos, algo salio mal al cargar las categorías<br> revisa tu conexión e intenta de nuevo.',
            titleColor: '#f8f8ff',
            color: '#ff5131',
            position: 'topRight'
          });
      }
    ); // fin del subscribe
  }

  existCat(listCategories: any[]): boolean {
    let found = false;
    for (let i = 0; i < listCategories.length; i++) {

      console.log(this.convertirCatRutas(listCategories[i].name) + '==' + this.categoria.ruta);
      if (this.convertirCatRutas(listCategories[i].name) == this.categoria.ruta) {
        this.categoria.id = listCategories[i].id; // actualizamos el id
        this.urlImageCat = listCategories[i].image; // actualizamos la imágen
        found = true;
        break;
      }
    }

    return found;
  }

  selectCatMobile() {

    for (let i = 0; i < this.listCategories.length; i++) {
      if (this.listCategories[i].id == this.categoria.id) {
        console.log(this.listCategories[i].id + '==' + this.categoria.id);
        const cat = this.listCategories[i].name;
        this.router.navigateByUrl('/menu/restaurante/' + this.convertirCatRutas(cat));
        break;
      }
    }

  }

  activateNav(name: string) {
    name = this.convertirCatRutas(name);
    if (name == this.actRoute.snapshot.params.cat) {
      return 'active';
    }

    return '';
  }

  getLength(text: string) {
    return text.length;
  }

  showDir() {
    $('#h3-suc').show();
    $('#select-suc').show();
    $('.direction-suc').show();
    $('.close-select-suc').show();
    $('.espacio-suc').show();
  }

  hideDir() {
    $('#h3-suc').hide();
    $('#select-suc').hide();
    $('.direction-suc').hide();
    $('.close-select-suc').hide();
    $('.espacio-suc').hide();
  }

  convertirCatRutas(ruta: string) {
    let rutaFinal = '';
    // remplazar caracteres especiales
    ruta = ruta.replace(/á/g, 'a');
    ruta = ruta.replace(/é/g, 'e');
    ruta = ruta.replace(/í/g, 'i');
    ruta = ruta.replace(/ó/g, 'o');
    ruta = ruta.replace(/ú/g, 'u');
    ruta = ruta.replace(/ñ/g, 'n');
    ruta = ruta.toLowerCase() ; // convertir en minusculas y solo guardar caracteres de código ascii
    for (let i = 0; i < ruta.length; i++) {
      // console.log(ruta.charAt(i));
      if (ruta.charCodeAt(i) > 96 &&  ruta.charCodeAt(i) < 123) {
        // console.log( ruta.charAt(i));
        rutaFinal += ruta.charAt(i);
      }
    }

    return rutaFinal;
  }





}

import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {


  constructor(private  http: HttpClient) { }

  getProducts() {
    const headers = new HttpHeaders({'content-Type': 'application/json'});
    return this.http.get(environment.BASE_API + '/products/', {headers});
  }



  getCategories() {
    const headers = new HttpHeaders({'content-Type': 'application/json'});
    return this.http.get(environment.BASE_API + '/categories', {headers});
  }

  getCategoriesDomSuc(idSuc: number) {
    const headers = new HttpHeaders({'content-Type': 'application/json'});
    // https://sandboxmb.com/api/categories?domicilio=1&sucursal=3
    return this.http.get(environment.BASE_API + '/categories?domicilio=1&sucursal=' + idSuc, {headers});
  }

  getCategoriesPromoSuc(idSuc: number) {
    const headers = new HttpHeaders({'content-Type': 'application/json'});
    // https://sandboxmb.com/api/categories?categories?promocion=1&domicilio=1&sucursal=1
    return this.http.get(environment.BASE_API + '/categories?promocion=1&sucursal=' + idSuc, {headers});
  }


  getProductsCategories(idCat: number) {
    const headers = new HttpHeaders({'content-Type': 'application/json'});
    return this.http.get(environment.BASE_API + '/products/' + idCat, {headers});
  }

  getProductsCategoriesSuc(idCat: number, idSuc: number) {
    const headers = new HttpHeaders({'content-Type': 'application/json'});
    return this.http.get(environment.BASE_API + '/menu/' + idCat + '?sucursal=' + idSuc, {headers});
  }


  getProductsCategoriesFam(idCat: number) {
    const headers = new HttpHeaders({'content-Type': 'application/json'});
    return this.http.get(environment.BASE_API + '/menufamilia/' + idCat , {headers});
  }
  getProductsCategoriesFamDomSuc(idCat: number, idSuc: number) {
    const headers = new HttpHeaders({'content-Type': 'application/json'});
    return this.http.get(environment.BASE_API + '/menufamilia/' + idCat + '?domicilio=1&?sucursal=' + idSuc , {headers});
  }
  postProduct(formData) {
    return this.http.post(environment.BASE_API + '/addProduct', formData);
  }


}

import { Component, OnInit, AfterViewChecked } from '@angular/core';
import * as $ from 'jquery';
import {Router} from '@angular/router';
import {OrdersService} from '../../services/orders/orders.service';
import {AppState} from '../../app.reducers';
import {Store} from '@ngrx/store';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit, AfterViewChecked {
  listOrders: any[] = [];
  resp: any;
  totalProducts: number;
  constructor(private  ordersService: OrdersService,
              private store: Store <AppState>,
              private router: Router) {
    this.store.subscribe(refresh => {
      this.resp = refresh;
      if (this.resp.orden === true) {
        this.getOrderProducts(); // <-- recargar las ordenes
      }
    });
  }

  ngOnInit() {
    this.getOrderProducts();
    $(document).on('click', '.navbar-collapse.in', function(e) {
      if ( $(e.target).is('a:not(".dropdown-toggle")') ) {
        $(this).collapse('hide');
      }
    });
  }
  ngAfterViewChecked() {
    if (this.router.url === '/') {
      $('.nav-link').removeClass('active');
      $('.dropdown').removeClass('active');
      $('.subitem').removeClass('active');
      $('#home').addClass('active');
      $("#socialBarra").hide();
    }
    // NOSOTROS y sus subsecciones

    if (this.router.url === '/valores') {
      $('.nav-link').removeClass('active');
      $('.dropdown').removeClass('active');
      $('.subitem').removeClass('active');
      $('#nosotros').addClass('active');
      $("#socialBarra").show();
    }
    if (this.router.url === '/blog') {
      $('.nav-link').removeClass('active');
      $('.dropdown').removeClass('active');
      $('.subitem').removeClass('active');
      $('#nosotros').addClass('active');
      $("#socialBarra").show();
    }
    if (this.router.url === '/cafeypan') {
      $('.nav-link').removeClass('active');
      $('.dropdown').removeClass('active');
      $('.subitem').removeClass('active');
      $('#nosotros').addClass('active');
      $("#socialBarra").show();
    }

    if (this.router.url === '/menu-carbon') {
      $('.nav-link').removeClass('active');
      $('.dropdown').removeClass('active');
      $('.subitem').removeClass('active');
      $('#menu-carbon').addClass('active');
      $("#socialBarra").show();
    }

    // Menu y sus subsecciones


    if (this.router.url.substr(0, 17 ) === '/menu/restaurante') {
      $('.nav-link').removeClass('active');
      $('.dropdown').removeClass('active');
      $('.subitem').removeClass('active');
      $('#menu').addClass('active');
      $("#socialBarra").show();
    }
    if (this.router.url.substr(0, 16 ) === '/menu/parallevar') {
      $('.nav-link').removeClass('active');
      $('.dropdown').removeClass('active');
      $('.subitem').removeClass('active');
      $('#menu').addClass('active');
      $("#socialBarra").show();
    }
    if (this.router.url.substr(0, 26 ) === '/menu/promocionesypaquetes') {
      $('.nav-link').removeClass('active');
      $('.dropdown').removeClass('active');
      $('.subitem').removeClass('active');
      $('#menu').addClass('active');
      $("#socialBarra").show();
    }
    if (this.router.url === '/menu/el-secreto-esta-en-la-magia') {
      $('.nav-link').removeClass('active');
      $('.dropdown').removeClass('active');
      $('.subitem').removeClass('active');
      $('#menu').addClass('active');
      $("#socialBarra").show();
    }
    if (this.router.url === '/menu/diademuertos') {
      $('.nav-link').removeClass('active');
      $('.dropdown').removeClass('active');
      $('.subitem').removeClass('active');
      $('#menu').addClass('active');
      $("#socialBarra").show();
    }
    if (this.router.url === '/sucursales') {
      $('.nav-link').removeClass('active');
      $('.dropdown').removeClass('active');
      $('.subitem').removeClass('active');
      $('#sucursales').addClass('active');
      $("#socialBarra").show();
    }

    if (this.router.url === '/facturacion') {
      $('.nav-link').removeClass('active');
      $('.dropdown').removeClass('active');
      $('.subitem').removeClass('active');
      $('#facturacion').addClass('active');
      $("#socialBarra").show();
    }


    // Contacto y sus subsecciones
    if (this.router.url === '/contacto') {
      $('.nav-link').removeClass('active');
      $('.dropdown').removeClass('active');
      $('.subitem').removeClass('active');
      $('#contacto').addClass('active');
      $("#socialBarra").show();
    }
    if (this.router.url === '/proveedores') {
      $('.nav-link').removeClass('active');
      $('.dropdown').removeClass('active');
      $('.subitem').removeClass('active');
      $('#contacto').addClass('active');
      $("#socialBarra").show();

    }

    if (this.router.url === '/vacantes') {
      $('.nav-link').removeClass('active');
      $('.dropdown').removeClass('active');
      $('.subitem').removeClass('active');
      $('#contacto').addClass('active');
      $("#socialBarra").show();
    }


    if (this.router.url === '/orden') {
      $('.nav-link').removeClass('active');
      $('.dropdown').removeClass('active');
      $('.subitem').removeClass('active');
      $('#mi-orden').addClass('active');
      $("#socialBarra").show();
    }
    // eventos

    if (this.router.url === '/de-pesca-con-madero') {
      $('.nav-link').removeClass('active');
      $('.dropdown').removeClass('active');
      $('.subitem').removeClass('active');
      $('#menu').addClass('active');
      $("#socialBarra").show();
    }
    if (this.router.url === '/un-momento-unico-con-mama') {
      $('.nav-link').removeClass('active');
      $('.dropdown').removeClass('active');
      $('.subitem').removeClass('active');
      $('#menu').addClass('active');
      $("#socialBarra").show();

    }

    if (this.router.url === '/reservaciones') {
      $('.nav-link').removeClass('active');
      $('.dropdown').removeClass('active');
      $('.subitem').removeClass('active');
      $('#reservacion').addClass('active');
      $("#socialBarra").show();
    }

  }
  getOrderProducts() {
    this.totalProducts = 0;
    this.ordersService.getOrderProducts().subscribe(
      res => {
        this.resp = res;
        // console.log('ordenes** ' +  JSON.stringify(res));
        this.listOrders = this.resp.content;
        this.calcAmounOrder();
      },
      err => {
        console.error(err);
      }
    );
  }

  calcAmounOrder() {

    for (let i = 0; i < this.listOrders.length; i++) {
      this.totalProducts += this.listOrders[i].amount;
    }

  }

  collapseClose() {

    $('#navbar').addClass('collapsing');
    $('#navbar').removeClass('in');

    $('#navbar').attr('aria-expanded', 'false');
    $('#navbar').removeClass('collapsing');

  }

}

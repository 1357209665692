import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CoberturaCiudadesModel} from '../../models/coberturaCiudadesModel';
import {CoberturaSucursalesModel} from '../../models/coberturaSucursalesModel';
import * as $ from 'jquery';
import {of} from 'rxjs';
import {NgxIzitoastService} from 'ngx-izitoast';
import {CoberturaColoniasModel} from '../../models/coberturaColoniasModel';

@Component({
  selector: 'app-cobertura-a-domicilio',
  templateUrl: './cobertura-a-domicilio.component.html',
  styleUrls: ['./cobertura-a-domicilio.component.css']
})
export class CoberturaADomicilioComponent implements OnInit {

  public listCiudades: CoberturaCiudadesModel[] = [];
  private resp: any;

  public citySelected: string;
  public listSucursales: CoberturaSucursalesModel[] = [];
  public indiceCity: number;
  public cp: number;

  public buscando: boolean;

  constructor(private httpClient: HttpClient, public iziToast: NgxIzitoastService) {
    this.listCiudades.length = 0;
    this.listSucursales.length = 0;
    this.citySelected = '';
    this.indiceCity = 0;
    this.cp = null;
    this.buscando = false;
  }

  ngOnInit() {
    this.httpClient.get('/assets/json/coberturaSucursales.json').subscribe(data => {
     // console.log(data);
      this.resp = data;
      this.listCiudades = this.resp;
      console.log(this.listCiudades);

      this.indiceCity = 0;
      this.searchCity();

    });
  }

  search(): void {
    if (!this.cp) {
      this.iziToast.show({
        image: './assets/ico/error.png',
        title:  'Ingresa por favor el Código Postal',
        position: 'topCenter',
        theme: 'dark',

      });

      $('#cp').focus();
      return;
    }

    if (this.cp.toString().length != 5) {
      this.iziToast.show({
        image: './assets/ico/error.png',
        title:  'Un Código Postal debe tener solo 5 dígitos.',
        position: 'topCenter',
        theme: 'dark',

      });

      $('#cp').focus();
      return;
    }
    this.buscando = true;



    this.listSucursales.length = 0;
    for (const item of this.listCiudades[this.indiceCity].sucursales) {


      for (const itemCol of item.colonias) {
        console.log(item.name);
        // console.log(itemCol);
        if (itemCol.CP ==  this.cp) {

          // si no esta en la lista la agrego
          if (!this.existsSuc(item.name)) {
            this.listSucursales.push(item);
          }

        }

      }
    }
 /*   if (this.listSucursales.length == 0){
      alert('no encontrado');
    }*/

  }


  existsSuc(nameSucursal: string): boolean{
    for (const item of this.listSucursales) {
      // console.log(item);
      if (nameSucursal == item.name){
        return true;
      }
    }

    return false;
  }

  searchCity(): void {
    this.listSucursales.length = 0;
    this.buscando = false;
    this.citySelected = this.listCiudades[this.indiceCity].name;
    // alert('Buscar en ' + this.listCiudades[this.indiceCity].name);
    for (const item of this.listCiudades[this.indiceCity].sucursales) {
          this.listSucursales.push(item);
    }
  }
}

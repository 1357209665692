import { Component, OnInit } from '@angular/core';

import * as $ from 'jquery';
import {NgxIzitoastService} from 'ngx-izitoast';
import {Store} from '@ngrx/store';

import {
  CollectAction,
  DeliverAction,
  GetModifiers, NotGetModifiers,
  NotRefreshAction, NotRefreshModifiers,
  RefreshAction, RefreshModifiers
} from '../../../redux/ordenes.action';
import {Router} from '@angular/router';
import {OrdersService} from '../../services/orders/orders.service';
import {AppState, AppStateDeliver, AppStateModifiers} from '../../app.reducers';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.css']
})
export class OrderDetailsComponent implements OnInit {
  resp: any;
  amount: number[] = [];
  listOrders: any[] = [];
  listOrderModifiers: any[] = [];
  btnCheckout: boolean;
  typeOrder: number;
  productIdModifiers: number;
  productModifiers: number;
  loadOrders: boolean;
  constructor(private ordersService: OrdersService,
              public iziToast: NgxIzitoastService,
              private store: Store <AppState>,
              private storeDeliver: Store <AppStateDeliver>,
              private storeModifiers: Store <AppStateModifiers>,
              private router: Router) {

    this.getOrderProducts();
    for (let i = 0; i < 12; i++) {
      this.amount.push(i);
    }
    this.btnCheckout = false;


    // Redux Reload order refresh
    this.store.subscribe(refresh => {
      this.resp = refresh;
      if (this.resp.orden === true) {
        this.getOrderProducts(); // <-- recargar las ordenes
      }
    });

    // Redux Reload type order
    this.storeDeliver.subscribe(typeOrder => {
      this.resp = typeOrder;
      // console.log(typeOrder);
      // console.log(this.resp.type);

      this.typeOrder = this.resp.type;
      // console.log(this.typeOrder);
      // console.log('--------------');

    });

    // Redux listenig type Action
    this.storeModifiers.subscribe(refresh => {
      this.resp = refresh;
      console.log(this.resp);
      if (this.resp.modifiers === 3) {
        this.getOrderProducts(); // <-- recargar las ordenes cuando se actualiza un modificador
      }
    });


  }

  ngOnInit() {
    this.getOrderProducts();
    if (this.router.url !== '/pedidos') {
      this.btnCheckout = true;
    }
  }

  getOrderProducts() {
    this.loadOrders = false;
    this.ordersService.getOrderProducts().subscribe(
      res => {
        this.resp = res;
        // console.log(this.resp);
        this.listOrders = this.resp.content;
        this.loadOrders = true;
        this.getProductModificadores();
      },
      err => {
        console.error(err);
        this.iziToast.show(
          {
            title: 'Lo sentimos, algo salio mal al cargar los productos de tu orden.<br> revisa tu conexión e intenta de nuevo.',
            titleColor: '#f8f8ff',
            color: '#ff5131',
            position: 'topRight'
          });
      }
    );
  }




    getProductModificadores() {
    for (let iOrder = 0; iOrder < this.listOrders.length; iOrder++) {
      this.ordersService.getProductModifiers( this.listOrders[iOrder].id).subscribe(
        res => {
          this.resp = res;
          console.log(this.resp);
          this.listOrderModifiers[iOrder] = this.resp.content;
        },
        err => {
          console.error(err);
          this.iziToast.show(
            {
              title: 'Lo sentimos, algo salio mal al cargar los modificadores.<br> revisa tu conexión e intenta de nuevo.',
              titleColor: '#f8f8ff',
              color: '#ff5131',
              position: 'topRight'
            });
        }
      );
    }
  }
  deleteModifiers(idModif: number, idOrderProduct: number) {
    // alert('ind modifiers ' + idModif + 'idORderPRoduct ' + idOrderProduct);
    const formData = new FormData();

    formData.append('opcion', '1'); // <- 1 es para eliminar
    formData.append('modificador', idModif.toString());

    this.ordersService.postProductModifiers(formData, idOrderProduct).subscribe(
      res => {
        this.resp = res;
        if (this.resp.success) {
          console.log('response:' + JSON.stringify(this.resp));

          this.iziToast.show(
            {
              title: 'Se ha eliminado correctamente',
              titleColor: '#f8f8ff',
              color: '#535353',
              position: 'topRight',
              closeOnEscape: true,

            });
          // redux recargar
          const action = new RefreshModifiers();
          this.storeModifiers.dispatch(action);
          // redux dejar de recargar
          const action2 = new NotRefreshModifiers();
          this.storeModifiers.dispatch(action2);


        } else {

          this.iziToast.show(
            {
              title: 'Lo sentimos, algo salio mal al eliminar el modificador.<br> revisa tu conexión e intenta de nuevo.',
              titleColor: '#f8f8ff',
              color: '#ff5131',
              position: 'topRight'
            });
        }
      },
      err => {
        console.error(err);
        this.iziToast.show(
          {
            title: 'Lo sentimos, no se pudo eliminar el modificador.<br> revisa tu conexión e intenta de nuevo.',
            titleColor: '#f8f8ff',
            color: '#ff5131',
            position: 'topRight'
          });
      }
    );


  }



  deleteProduct(id: number, name: string) {
    const formData = new FormData();
    formData.append('product', id.toString() );

    this.ordersService.removeProduct(formData).subscribe(
      res => {
        this.resp = res;
        console.log(this.resp);
        if (this.resp.success) {
          console.log('response:' + JSON.stringify(this.resp));
          this.iziToast.show(
            {
              title: 'Eliminado <br>' + name + '<br>del carrito de compras.',
              titleColor: '#f8f8ff',
              color: '#535353',
              position: 'topRight'
            });
          // redux recargar
          const action = new RefreshAction();
          this.store.dispatch(action);
          // redux dejar de recargar
          const action2 = new NotRefreshAction();
          this.store.dispatch(action2);
        } else {

          this.iziToast.show(
            {
              title: this.resp.msg_error,
              titleColor: '#f8f8ff',
              color: '#ff5131',
              position: 'topRight'
            });
        }
      },
      err => {
        console.error(err);
      }
    );
  }

  updateAmountProduct(idOrder: number , indice: number) {
    const amount = this.listOrders[indice].amount;
    const formData = new FormData();
    // alert('actualizar cantidad \n order' + idOrder + ' cantidad ' + this.listOrders[indice].amount);
    formData.append('product', idOrder.toString() );
    formData.append('cant', amount );

    this.ordersService.editAmountProduct(formData).subscribe(
      res => {
        this.resp = res;
        console.log(this.resp);
        if (this.resp.success) {
          console.log('response:' + JSON.stringify(this.resp));
          // this.iziToast.show(
          //   {
          //     title: 'Update to shopping cart',
          //     titleColor: '#f8f8ff',
          //     color: '#000',
          //     position: 'topRight'
          //   });
          // redux recargar
          const action = new RefreshAction();
          this.store.dispatch(action);
          // redux dejar de recargar
          const action2 = new NotRefreshAction();
          this.store.dispatch(action2);
        } else {

          this.iziToast.show(
            {
              title: this.resp.msg_error,
              titleColor: '#f8f8ff',
              color: '#ff5131',
              position: 'topRight'
            });
        }
      },
      err => {
        console.error(err);
        this.iziToast.show(
          {
            title: 'Lo sentimos, algo salio mal.<br> revisa tu conexión e intenta de nuevo.',
            titleColor: '#f8f8ff',
            color: '#ff5131',
            position: 'topRight'
          });
      }
    );

  }

  modifiersProduct(idProduct: number, product: number, dispositivo: number) {
    if (dispositivo == 1) { // pc
      this.productIdModifiers = idProduct;
      this.productModifiers = product;
      document.getElementById('modal-modifiers').click();

    } else {// dispositivo mobile
      this.router.navigate(['/modificadores-details', idProduct]);
    }

  }

  calcTotal() {
    let total = 0;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.listOrders.length; i++) {
      total += this.listOrders[i].price_unit * this.listOrders[i].amount;
    }
    return total;
  }

  calcAmount() {
    let total = 0;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.listOrders.length; i++) {
      total += this.listOrders[i].amount;
    }
    return total;
  }

  selectTypeOrder() {
    // redux recargar
    if ( this.typeOrder == 1) {

      // alert(this.typeOrder + 'true');
      const action = new DeliverAction();
      this.storeDeliver.dispatch(action);

    } else if (this.typeOrder == 0) {

      // alert(this.typeOrder + 'false');
      const action = new CollectAction();
      this.storeDeliver.dispatch(action);
    }


  }

}

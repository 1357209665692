import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-menu-qr',
  templateUrl: './menu-qr.component.html',
  styleUrls: ['./menu-qr.component.css']
})
export class MenuQrComponent implements OnInit, OnChanges {
  @Input() url ;

  pdfSrcSecure: SafeResourceUrl;
  constructor( private  sanitizer: DomSanitizer) { }

  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges): void {

    // tslint:disable-next-line:triple-equals
    if (changes.url.currentValue != undefined) {
      // console.log(this.total)
      console.log(this.url);
      this.pdfSrcSecure = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);

    }

    // You can also use categoryId.previousValue and
    // categoryId.firstChange for comparing old and new values

  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lealtad',
  templateUrl: './lealtad.component.html',
  styleUrls: ['./lealtad.component.css']
})
export class LealtadComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.getElementById('modalML').click();
  }

}

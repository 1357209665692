import {Action} from '@ngrx/store';
import {
  COLLECT,
  DELIVER,
  GETMODIFIERS,
  NOTGETMODIFIERS,
  NOTREFRESH,
  NOTREFRESHMODIFIERS, NOTREFRESHSUC, NOTREFRESHSUC2, NOTREFRESHSUC3, NOTREFRESHSUC4,
  REFRESH,
  REFRESHMODIFIERS, REFRESHSUC, REFRESHSUC2, REFRESHSUC3, REFRESHSUC4
} from './ordenes.action';

export function ordenesReducer(state: boolean = false, action: Action) {

  switch (action.type) {
    case REFRESH:
      return true;

    case NOTREFRESH:
      return false;

    default:
      return state;

  }
}

export function typeOrderReducer(state: number = 0, action: Action) {

  switch (action.type) {
    case DELIVER:
      return 1;

    case COLLECT:
      return 0;

    default:
      return state;

  }
}

export function modifiersReducer(state: number = 0, action: Action) {

  switch (action.type) {
    case GETMODIFIERS:
      return 1;

    case NOTGETMODIFIERS:
      return 2;

    case REFRESHMODIFIERS:
      return 3;

    case NOTREFRESHMODIFIERS:
      return 4;
    default:
      return state;

  }
}

export function sucursalReducer(state: boolean = false, action: Action) {

  switch (action.type) {
    case REFRESHSUC:
      return true;

    case NOTREFRESHSUC:
      return false;

    default:
      return state;

  }
}


export function sucursalReducer2(state: boolean = false, action: Action) {

  switch (action.type) {
    case REFRESHSUC2:
      return true;

    case NOTREFRESHSUC2:
      return false;

    default:
      return state;

  }
}

export function sucursalReducer3(state: boolean = false, action: Action) {

  switch (action.type) {
    case REFRESHSUC3:
      return true;

    case NOTREFRESHSUC3:
      return false;

    default:
      return state;

  }
}

export function sucursalReducer4(state: boolean = false, action: Action) {

  switch (action.type) {
    case REFRESHSUC4:
      return true;

    case NOTREFRESHSUC4:
      return false;

    default:
      return state;

  }
}

import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private  http: HttpClient) { }

  sendInvoice(formData) {
    return this.http.post(environment.BASE_API + '/solicitar_factura', formData);
  }
  sendContactoInfo(formData) {
    return this.http.post(environment.BASE_API + '/contacto_info', formData);
  }
  sendContactoProv(formData) {
    return this.http.post(environment.BASE_API + '/contacto_prov', formData);
  }
  sendContactoEquipo(formData) {
    return this.http.post(environment.BASE_API + '/contacto_api', formData);
  }

  sendCalaverita(formData) {
    return this.http.post(environment.BASE_API + '/calaveritas/', formData);
  }



}

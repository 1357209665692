import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {NotRefreshAction, RefreshAction} from '../../../redux/ordenes.action';
import {Store} from '@ngrx/store';
import {AppState, AppStateDeliver} from '../../app.reducers';
import {NgxIzitoastService} from 'ngx-izitoast';
import {ProductsService} from '../../services/products/products.service';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-products-cat',
  templateUrl: './products-cat.component.html',
  styleUrls: ['./products-cat.component.css']
})
export class ProductsCatComponent implements OnInit, OnChanges {
  @Input() idCategoria: number;
  @Input() posibilityBuy: boolean;
  @Input() selectSuc: boolean;
  BASE_URL = environment.BASE_URL;
  resp: any;

  listProductsCategories: any[] = [];
  loadingProductsCategories: boolean;
  productIdModifiers: number;
  productModifiers: number;

  constructor(public iziToast: NgxIzitoastService,
              private  productsService: ProductsService,
              private router: Router,
              private store: Store <AppState>) {
    // inicializar loading false para que aparezca que este cargando
    this.loadingProductsCategories = false;

  }

  ngOnInit() {
    if (this.idCategoria !== undefined) {
      // alert('carga inicial');
      this.getCategoriesProductFam(this.idCategoria);
    }

  }
  ngOnChanges(changes: SimpleChanges) {

    if (changes.idCategoria.currentValue == undefined) {

    } else {
      // alert('change' + this.idCategoria);
      this.listProductsCategories.length = 0;
      this.getCategoriesProductFam(this.idCategoria);

    }

    // You can also use categoryId.previousValue and
    // categoryId.firstChange for comparing old and new values

  }

  getCategoriesProductFam(idCat: number) {
    this.loadingProductsCategories = false;

      // seleccionar sin domicilllio osea sin sucursal
    if (!this.selectSuc) {

      this.productsService.getProductsCategories(idCat).subscribe(
        res => {
          this.resp = res;
          this.listProductsCategories = this.resp.content;
          this.loadingProductsCategories = true;
          console.log(this.listProductsCategories);
        },
        err => {
          console.error(err);
          this.loadingProductsCategories = true;
          this.iziToast.show(
            {
              title: 'Lo sentimos, algo salio mal al cargar los productos del menú \n revisa tu conexión e intenta de nuevo.',
              titleColor: '#f8f8ff',
              color: '#ff5131',
              position: 'topRight'
            });
        }
      ); // fin del subscribe
    } else { // seleccionar los productos con sucursal
      // tslint:disable-next-line:radix
      this.productsService.getProductsCategoriesFamDomSuc(idCat, parseInt(localStorage.getItem('selectSuc'))  ).subscribe(
        res => {
          this.loadingProductsCategories = true;
          this.resp = res;
          this.listProductsCategories = this.resp.content;

          console.log(this.listProductsCategories);
        },
        err => {
          this.loadingProductsCategories = true;
          console.error(err);
          this.iziToast.show(
            {
              title: 'Lo sentimos, algo salio mal al cargar los productos del menú \n revisa tu conexión e intenta de nuevo.',
              titleColor: '#f8f8ff',
              color: '#ff5131',
              position: 'topRight'
            });
        }
      ); // fin del subscribe
    }

  }



  addProductCart(id: number, name: string, redirect: boolean ) {

    if (localStorage.getItem('selectSuc') == null) {
      this.iziToast.show(
        {
          title: 'Selecciona una sucursal porfavor<br>para poder agregar tu producto al carrito.',
          titleColor: '#f8f8ff',
          color: '#ff5131',
          position: 'topRight'
        });
      return ;
    }



    const formData = new FormData();
    formData.append('product', id.toString() );
    formData.append('sucursal', localStorage.getItem('selectSuc') );


    this.productsService.postProduct(formData).subscribe(
      res => {
        this.resp = res;
        if (this.resp.success) {
          console.log('response:' + JSON.stringify(this.resp));
          if ( this.resp.modificadores > 0 ) { // si tiene modificadores disponibles para elegir pedirlos

            this.modifiersProduct(this.resp.id, this.resp.product , redirect);
          }

          this.iziToast.show(
            {
              title: '<a href="/orden" style="color: white">Se ha agregado <br>' + name + '<br> al carrito de compras.</a>',
              titleColor: '#f8f8ff',
              color: '#4CAF50',
              position: 'topRight',
              closeOnEscape: true,

            });

          // redux recargar el numero de orednes
          const action = new RefreshAction();
          this.store.dispatch(action);
          // redux dejar de recargar
          const action2 = new NotRefreshAction();
          this.store.dispatch(action2);

        } else {

          this.iziToast.show(
            {
              title: this.resp.msg_error,
              titleColor: '#f8f8ff',
              color: '#ff5131',
              position: 'topRight'
            });
        }
      },
      err => {
        console.error(err);
        this.iziToast.show(
          {
            title: 'Lo sentimos, algo salio mal.\n revisa tu conexión e intenta de nuevo.',
            titleColor: '#f8f8ff',
            color: '#ff5131',
            position: 'topRight'
          });
      }
    );

  }
  modifiersProduct(idProduct: number, product: number, redirect: boolean) {
    console.log('change modal modifiers' +  this.productIdModifiers + 'productModifiers ' + this.productModifiers);
    if (!redirect) { // pc
      this.productIdModifiers = idProduct;
      this.productModifiers = product;

      document.getElementById('modal-modifiers').click();

    } else {// dispositivo mobile
      this.router.navigate(['/modificadores', idProduct, product]);
    }

  }




  getLength(text: string) {
    return text.length;
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-carbon',
  templateUrl: './menu-carbon.component.html',
  styleUrls: ['./menu-carbon.component.css']
})
export class MenuCarbonComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  closeModal() {
    document.getElementById('btn-cerrar').click();
   /*console.log(localStorage.getItem('modalViewNavidad'));
    if (localStorage.getItem('modalViewNavidad') == null) {
      localStorage.setItem('modalViewNavidad', 'false');
    }*/
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-qr-to-go',
  templateUrl: './qr-to-go.component.html',
  styleUrls: ['./qr-to-go.component.css']
})
export class QrToGoComponent implements OnInit {

  pdfSrc: string;
  zoom: number;
  constructor() {
    this.pdfSrc = 'assets/pdf/menus/menuParallevar.pdf';
    this.zoom = 0;

  }

  ngOnInit() {

    // similar behavior as an HTTP redirect
    if (!this.sistemOS()) {
      // alert(this.sistemOS());
      window.location.replace(this.pdfSrc);
    }

  }

  sistemOS(): boolean {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      // true for mobile device
      return false;
    } else {
      // false for not mobile device
      return true;
    }

  }

}
